/* theme.less file that will be compiled */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857143;
  color: #4d4d4d;
  background-color: #fafafa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #007bb1;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #004665;
  text-decoration: underline;
}
a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #cccccc;
}
.sr-only,
.hiddenStructure,
.crud-form .header-select {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: #fff;
  background-color: #000;
}
.sr-only a,
.hiddenStructure a {
  color: #fff;
  background-color: #000;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #4d4d4d;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 300;
}
label {
  display: inline-block;
  max-width: 95%;
  vertical-align: top;
  margin-bottom: 6px;
  font-weight: bold;
}
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
  min-width: 10em;
}
select[size="1"] {
  height: 34px;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #696969;
}
/*.form-control,*/
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea,
select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #696969;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border-color: #64bee8;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(100, 190, 232, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(100, 190, 232, 0.6);
}
input[type="text"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
  color: #777777;
  opacity: 1;
}
input[type="text"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #777777;
}
input[type="text"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #777777;
}
input[type="text"][disabled],
input[type="number"][disabled],
input[type="email"][disabled],
input[type="password"][disabled],
textarea[disabled],
select[disabled],
input[type="text"][readonly],
input[type="number"][readonly],
input[type="email"][readonly],
input[type="password"][readonly],
textarea[readonly],
select[readonly],
fieldset[disabled] input[type="text"],
fieldset[disabled] input[type="number"],
fieldset[disabled] input[type="email"],
fieldset[disabled] input[type="password"],
fieldset[disabled] textarea,
fieldset[disabled] select {
  cursor: not-allowed;
  background-color: #e5e5e5;
  opacity: 1;
}
textarea {
  min-height: 9em;
  min-width: 10em;
  resize: vertical;
}
textarea[name='form.widgets.IRichText.text'] {
  min-height: 12em;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
select {
  width: unset;
  display: inline-block;
}
input[type="search"] {
  -webkit-appearance: none;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  line-height: 34px;
  line-height: 1.42857143 \0;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}
.field {
  margin-bottom: 15px;
}
.field span.option {
  display: block;
}
.field.error {
  background: #fbe8e4;
  -webkit-box-shadow: 0 0 0 5px #fbe8e4;
  box-shadow: 0 0 0 5px #fbe8e4;
}
.field.error .help-block,
.field.error .control-label,
.field.error .radio,
.field.error .checkbox,
.field.error .radio-inline,
.field.error .checkbox-inline {
  color: rgba(0, 0, 0, 0.5);
}
.field.error .form-control {
  border-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.field.error .form-control:focus {
  border-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(51, 51, 51, 0.5);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(51, 51, 51, 0.5);
}
.field.error .input-group-addon {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5);
  background-color: #EC9C8D;
}
.field.error .form-control-feedback {
  color: rgba(0, 0, 0, 0.5);
}
.field.error > label,
.field.error div.error {
  color: #c4183c;
}
.field.error div.error {
  padding-left: 30px;
  font-weight: 400;
}
.field.error div.error::before {
  background-color: #ac493f;
  content: "×";
  font-size: 14px;
  font-weight: 400;
  margin-left: -30px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.5);
  top: -1px;
  position: relative;
}
.field.error input,
.field.error textarea,
.field.error select {
  border-color: #c4183c;
}
.field.error input:focus,
.field.error textarea:focus,
.field.error select:focus {
  -webkit-box-shadow: 0 0 8px #f6b2c0;
  box-shadow: 0 0 8px #f6b2c0;
}
.formHelp {
  margin-top: 0;
  margin-bottom: 0;
  color: #666666;
  font-weight: 400;
  font-style: italic;
}
.formControls {
  border-top: 1px solid #cccccc;
  background: #fcfcfd;
  padding: 12px;
  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.05);
}
.plone-modal-body .formControls {
  border: none;
  background: transparent;
  padding: 0;
  box-shadow: none;
}
label .required::after {
  color: #c4183c;
  content: "•";
  font-size: 200%;
  line-height: 0;
  position: relative;
  top: 7px;
  margin-left: -4px;
}
.searchField.portlet-search-gadget,
#sharing-user-group-search,
#select-rules {
  width: 100%;
  display: inline-block;
}
.searchField.portlet-search-gadget + .searchButton,
#sharing-user-group-search + #sharing-search-button,
#select-rules + input {
  position: relative;
  float: right;
  margin-top: -34px;
}
#search-results li {
  margin: 12px 0;
}
#search-results li .croppedDescription {
  margin-bottom: 0;
}
.highlightedSearchTerm {
  background: #fea;
  box-shadow: 0 0 1px 1px #fea;
}
.search-date-options > div,
.search-type-options > div {
  display: inline-block;
  margin-right: 12px;
}
.optionsToggle label {
  font-weight: 300;
  color: #696969;
}
.ordered-selection-field td {
  vertical-align: middle;
  text-align: center;
}
.ordered-selection-field td button {
  display: block;
  margin: auto;
}
.folder-factories {
  list-style: none;
}
.widget input + label {
  display: inline;
}
.standalone,
.context,
.destructive,
[type="submit"],
button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 1px rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.standalone:focus,
.context:focus,
.destructive:focus,
[type="submit"]:focus,
button:focus,
.standalone:active:focus,
.context:active:focus,
.destructive:active:focus,
[type="submit"]:active:focus,
button:active:focus,
.standalone.active:focus,
.context.active:focus,
.destructive.active:focus,
[type="submit"].active:focus,
button.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.standalone:hover,
.context:hover,
.destructive:hover,
[type="submit"]:hover,
button:hover,
.standalone:focus,
.context:focus,
.destructive:focus,
[type="submit"]:focus,
button:focus {
  color: #4d4d4d;
  text-decoration: none;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}
.standalone:active,
.context:active,
.destructive:active,
[type="submit"]:active,
button:active,
.standalone.active,
.context.active,
.destructive.active,
[type="submit"].active,
button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.standalone.disabled,
.context.disabled,
.destructive.disabled,
[type="submit"].disabled,
button.disabled,
.standalone[disabled],
.context[disabled],
.destructive[disabled],
[type="submit"][disabled],
button[disabled],
fieldset[disabled] .standalone,
fieldset[disabled] .context,
fieldset[disabled] .destructive,
fieldset[disabled] [type="submit"],
fieldset[disabled] button {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.standalone,
[type="submit"],
button {
  color: #4d4d4d;
  background-color: #e5e5e5;
  border-color: #b2b2b2;
}
.standalone:hover,
[type="submit"]:hover,
button:hover,
.standalone:focus,
[type="submit"]:focus,
button:focus,
.standalone:active,
[type="submit"]:active,
button:active,
.standalone.active,
[type="submit"].active,
button.active,
.open > .dropdown-toggle.standalone,
.open > .dropdown-toggle[type="submit"],
.open > .dropdown-togglebutton {
  color: #4d4d4d;
  background-color: #cccccc;
  border-color: #939393;
}
.standalone:active,
[type="submit"]:active,
button:active,
.standalone.active,
[type="submit"].active,
button.active,
.open > .dropdown-toggle.standalone,
.open > .dropdown-toggle[type="submit"],
.open > .dropdown-togglebutton {
  background-image: none;
}
.standalone.disabled,
[type="submit"].disabled,
button.disabled,
.standalone[disabled],
[type="submit"][disabled],
button[disabled],
fieldset[disabled] .standalone,
fieldset[disabled] [type="submit"],
fieldset[disabled] button,
.standalone.disabled:hover,
[type="submit"].disabled:hover,
button.disabled:hover,
.standalone[disabled]:hover,
[type="submit"][disabled]:hover,
button[disabled]:hover,
fieldset[disabled] .standalone:hover,
fieldset[disabled] [type="submit"]:hover,
fieldset[disabled] button:hover,
.standalone.disabled:focus,
[type="submit"].disabled:focus,
button.disabled:focus,
.standalone[disabled]:focus,
[type="submit"][disabled]:focus,
button[disabled]:focus,
fieldset[disabled] .standalone:focus,
fieldset[disabled] [type="submit"]:focus,
fieldset[disabled] button:focus,
.standalone.disabled:active,
[type="submit"].disabled:active,
button.disabled:active,
.standalone[disabled]:active,
[type="submit"][disabled]:active,
button[disabled]:active,
fieldset[disabled] .standalone:active,
fieldset[disabled] [type="submit"]:active,
fieldset[disabled] button:active,
.standalone.disabled.active,
[type="submit"].disabled.active,
button.disabled.active,
.standalone[disabled].active,
[type="submit"][disabled].active,
button[disabled].active,
fieldset[disabled] .standalone.active,
fieldset[disabled] [type="submit"].active,
fieldset[disabled] button.active {
  background-color: #e5e5e5;
  border-color: #b2b2b2;
}
.standalone .badge,
[type="submit"] .badge,
button .badge {
  color: #e5e5e5;
  background-color: #4d4d4d;
}
.context {
  color: #fff;
  background-color: #007bb1;
  border-color: #00587e;
}
.context:hover,
.context:focus,
.context:active,
.context.active,
.open > .dropdown-toggle.context {
  color: #fff;
  background-color: #00587e;
  border-color: #002d41;
}
.context:active,
.context.active,
.open > .dropdown-toggle.context {
  background-image: none;
}
.context.disabled,
.context[disabled],
fieldset[disabled] .context,
.context.disabled:hover,
.context[disabled]:hover,
fieldset[disabled] .context:hover,
.context.disabled:focus,
.context[disabled]:focus,
fieldset[disabled] .context:focus,
.context.disabled:active,
.context[disabled]:active,
fieldset[disabled] .context:active,
.context.disabled.active,
.context[disabled].active,
fieldset[disabled] .context.active {
  background-color: #007bb1;
  border-color: #00587e;
}
.context .badge {
  color: #007bb1;
  background-color: #fff;
}
.destructive {
  color: #fff;
  background-color: #bb4f45;
  border-color: #963f37;
}
.destructive:hover,
.destructive:focus,
.destructive:active,
.destructive.active,
.open > .dropdown-toggle.destructive {
  color: #fff;
  background-color: #963f37;
  border-color: #692c26;
}
.destructive:active,
.destructive.active,
.open > .dropdown-toggle.destructive {
  background-image: none;
}
.destructive.disabled,
.destructive[disabled],
fieldset[disabled] .destructive,
.destructive.disabled:hover,
.destructive[disabled]:hover,
fieldset[disabled] .destructive:hover,
.destructive.disabled:focus,
.destructive[disabled]:focus,
fieldset[disabled] .destructive:focus,
.destructive.disabled:active,
.destructive[disabled]:active,
fieldset[disabled] .destructive:active,
.destructive.disabled.active,
.destructive[disabled].active,
fieldset[disabled] .destructive.active {
  background-color: #bb4f45;
  border-color: #963f37;
}
.destructive .badge {
  color: #bb4f45;
  background-color: #fff;
}
.link-parent {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 1px rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #4d4d4d;
  background-color: #e5e5e5;
  border-color: #b2b2b2;
  margin-bottom: 12px;
}
.link-parent:focus,
.link-parent:active:focus,
.link-parent.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.link-parent:hover,
.link-parent:focus {
  color: #4d4d4d;
  text-decoration: none;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}
.link-parent:active,
.link-parent.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.link-parent.disabled,
.link-parent[disabled],
fieldset[disabled] .link-parent {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.link-parent:hover,
.link-parent:focus,
.link-parent:active,
.link-parent.active,
.open > .dropdown-toggle.link-parent {
  color: #4d4d4d;
  background-color: #cccccc;
  border-color: #939393;
}
.link-parent:active,
.link-parent.active,
.open > .dropdown-toggle.link-parent {
  background-image: none;
}
.link-parent.disabled,
.link-parent[disabled],
fieldset[disabled] .link-parent,
.link-parent.disabled:hover,
.link-parent[disabled]:hover,
fieldset[disabled] .link-parent:hover,
.link-parent.disabled:focus,
.link-parent[disabled]:focus,
fieldset[disabled] .link-parent:focus,
.link-parent.disabled:active,
.link-parent[disabled]:active,
fieldset[disabled] .link-parent:active,
.link-parent.disabled.active,
.link-parent[disabled].active,
fieldset[disabled] .link-parent.active {
  background-color: #e5e5e5;
  border-color: #b2b2b2;
}
.link-parent .badge {
  color: #e5e5e5;
  background-color: #4d4d4d;
}
.link-parent::before {
  content: "↩ ";
  top: 3px;
  position: relative;
}
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group-addon:first-child {
  border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group-addon:last-child {
  border-left: 0;
}
.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
  z-index: 2;
}
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  margin-left: -1px;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.btn .caret {
  margin-left: 0;
}
.btn-lg .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}
.pat-autotoc.autotabs,
.autotabs {
  background: #fff;
  padding: 6px 12px;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}
.pat-autotoc.autotabs .autotoc-nav,
.autotabs .autotoc-nav {
  background: #f2f1f1;
  border-bottom: 1px solid #e5e5e5;
  margin: -6px -12px 6px;
}
.pat-autotoc.autotabs .autotoc-nav a,
.autotabs .autotoc-nav a {
  display: inline-block;
  padding: 12px;
  margin: 6px 0 0 6px;
  border: 1px solid #e5e5e5;
  margin-bottom: -1px;
  border-radius: 4px 4px 0 0;
  background: #fcfcfd;
  font-weight: 400;
}
.pat-autotoc.autotabs .autotoc-nav a:last-child,
.autotabs .autotoc-nav a:last-child {
  margin-right: 6px;
}
.pat-autotoc.autotabs .autotoc-nav a:hover,
.autotabs .autotoc-nav a:hover {
  text-decoration: none;
  background: #fff;
}
.pat-autotoc.autotabs .autotoc-nav a.active,
.autotabs .autotoc-nav a.active,
.pat-autotoc.autotabs .autotoc-nav a.active:hover,
.autotabs .autotoc-nav a.active:hover {
  color: #696969;
  cursor: default;
  background: #fff;
  border-bottom-color: #fff;
}
.pat-autotoc.autotabs .autotoc-nav .autotab-heading,
.autotabs .autotoc-nav .autotab-heading {
  display: inline-block;
  margin: 6px 0 0 6px;
}
.pat-autotoc.autotabs .formControls,
.autotabs .formControls {
  margin: 0 -12px -6px;
  border-radius: 0 0 4px 4px;
}
@media (max-width: 767px) {
  .autotabs .autotoc-nav {
    overflow-x: scroll;
    overflow-y: hidden;
    max-height: 52px;
    width: auto;
    white-space: nowrap;
    border-bottom: 0;
    box-shadow: inset 0 -1px #e5e5e5;
  }
  .autotabs .autotoc-nav a:not(.active) {
    box-shadow: inset 0 -1px #e5e5e5;
  }
}
.autotabs ul.autotoc-nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.autotabs ul.autotoc-nav li {
  list-style: none;
  display: inline-block;
}
.portlet.portletSiteSetup .portletContent > ul > li a::before {
  content: "";
}
.portlet.portletSiteSetup .portletContent > ul li:hover {
  background: #edecec;
}
.portletNavigationTree.portletSiteSetup nav.portletContent > ul > li a {
  border: none;
  padding: 0px;
  color: #4D4D4D;
}
.portletNavigationTree.portletSiteSetup nav.portletContent > ul > li a:hover {
  text-decoration: none;
}
.portletNavigationTree.portletSiteSetup a {
  border: none;
  text-align: center;
  height: 100%;
  padding: 0;
}
.portletNavigationTree.portletSiteSetup a span {
  font-size: 50px;
  text-align: center;
  display: block;
  color: #4d4d4d;
}
.portletNavigationTree.portletSiteSetup ul {
  background: #edecec;
  padding-bottom: 20px;
}
.portletNavigationTree.portletSiteSetup li {
  vertical-align: top;
}
.portletNavigationTree.portletSiteSetup ul > li > a:hover::after {
  content: "";
}
.portletNavigationTree.portletSiteSetup .inner-configlet {
  margin-top: 10px;
  padding-bottom: 10px;
}
.portletNavigationTree.portletSiteSetup .inner-configlet:hover {
  background: rgba(255, 255, 255, 0.67);
  border-radius: 5px;
  box-shadow: inset 1px 1px 3px #bbbbbb;
}
.portletNavigationTree.portletSiteSetup .inner-configlet:hover span {
  color: #333;
}
.template-overview-controlpanel .portlet.portletSiteSetup .portletContent > ul > li {
  height: 120px;
}
#portlet-prefs ul ul {
  padding-top: 10px;
}
#portlet-prefs ul ul li {
  padding-top: 10px;
}
#portlet-prefs ul ul li a {
  text-align: inherit;
  padding-left: 40px;
}
#portlet-prefs ul ul li a::before {
  content: "•";
  position: absolute;
  font-size: 25px;
  margin-top: -9px;
  left: 15px;
  color: #a7daf2;
}
.template-usergroup-groupprefs .field,
.template-usergroup-userprefs .field,
.template-usergroup-controlpanel .field,
.template-member-registration .field {
  margin-top: 2em;
}
.template-usergroup-groupprefs .formControls,
.template-usergroup-userprefs .formControls,
.template-usergroup-controlpanel .formControls,
.template-member-registration .formControls {
  border-top: 0;
  box-shadow: none;
  margin-bottom: 1em;
}
.template-usergroup-groupprefs table.listing,
.template-usergroup-userprefs table.listing,
.template-usergroup-controlpanel table.listing,
.template-member-registration table.listing {
  margin-bottom: 0.5em;
}
.template-usergroup-groupprefs input.quickSearch,
.template-usergroup-userprefs input.quickSearch,
.template-usergroup-controlpanel input.quickSearch,
.template-member-registration input.quickSearch {
  margin-bottom: 0.5em;
}
.template-usergroup-groupprefs .listingCheckbox,
.template-usergroup-userprefs .listingCheckbox,
.template-usergroup-controlpanel .listingCheckbox,
.template-member-registration .listingCheckbox {
  vertical-align: middle;
  text-align: center;
}
.template-usergroup-groupprefs form,
.template-usergroup-userprefs form {
  overflow: scroll;
}
.template-actions-controlpanel #content-core .addAction {
  height: 2em;
}
.template-actions-controlpanel #content-core section.portlet {
  clear: both;
}
.template-actions-controlpanel #content-core ol.configlets li {
  margin: 1.2em;
}
#textAdjust .decrease-text {
  font-size: 12px;
}
#textAdjust .normal-text {
  font-size: 14px;
}
#textAdjust .increase-text {
  font-size: 18px;
}
.pat-autotoc:not(.autotabs) .autotoc-nav {
  float: right;
  min-width: 150px;
  background-color: #f2f1f1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.17);
  padding: 6px 12px;
  margin: 0 0 12px 12px;
}
.pat-autotoc:not(.autotabs) .autotoc-nav a {
  display: block;
}
.pat-autotoc:not(.autotabs) .autotoc-nav .active,
.pat-autotoc:not(.autotabs) .autotoc-nav .active:hover {
  color: #696969;
  cursor: default;
  text-decoration: none;
}
.pat-autotoc:not(.autotabs) .autotoc-nav .autotoc-level-1 {
  margin-left: 0;
  font-size: 18px;
}
.pat-autotoc:not(.autotabs) .autotoc-nav .autotoc-level-2 {
  margin-left: 12px;
  font-size: 14px;
}
.pat-autotoc:not(.autotabs) .autotoc-nav .autotoc-level-3 {
  margin-left: 24px;
  font-size: 12px;
}
@media (max-width: 767px) {
  .pat-autotoc .autotoc-nav {
    float: none;
    margin: 0 0 20px 0;
  }
}
.pat-dropzone .dz-notice {
  margin-bottom: 6px;
}
.pat-dropzone .dz-default {
  border-radius: 6px;
  background-color: #f2f1f1;
  border: 3px dotted #cccccc;
  margin-bottom: 20px;
  padding: 12px;
  color: #696969;
}
.plone-modal {
  display: none;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  z-index: 1040;
  background: rgba(255, 255, 255, 0.5);
}
.plone-modal .plone-modal-dialog {
  width: 100%;
  position: relative;
}
.plone-modal .plone-modal-dialog .plone-modal-header {
  min-height: 25px;
  padding: 12px;
}
.plone-modal .plone-modal-dialog .plone-modal-header h3 {
  font-size: 18px;
  margin: 0;
}
.plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close {
  margin-top: -12px;
  float: right;
  font-size: 36px;
  font-weight: 100;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}
.plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close:hover,
.plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close:focus {
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}
.plone-modal .plone-modal-dialog .plone-modal-body {
  padding: 12px;
}
.plone-modal .plone-modal-dialog .plone-modal-body .modal-image {
  text-align: center;
}
.plone-modal .plone-modal-dialog .plone-modal-footer {
  border-top: 1px solid #cccccc;
  background: #fcfcfd;
  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.05);
  padding: 12px;
  text-align: right;
}
.plone-modal .plone-modal-dialog .plone-modal-footer .pattern-modal-buttons input + input {
  margin-left: 12px;
}
.plone-modal .plone-modal-content {
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  border: 0;
  border-radius: 4px;
  outline: none;
  background-clip: padding-box;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  max-width: 951px;
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-left: var(--grid-gutter);
  padding-right: 10px;
  padding-right: var(--grid-gutter);
  padding-left: 0;
  padding-right: 0;
}
.plone-modal.fade {
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.plone-modal.fade.in {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.plone-modal.fade .plone-modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.25s ease-out;
  -moz-transition: -moz-transform 0.25s ease-out;
  -o-transition: -o-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
}
.plone-modal.fade.in .plone-modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.plone-modal.in {
  display: block !important;
}
.plone-modal-loading {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #428bca;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  border-radius: 0.5em;
  width: 40em;
  height: 40px;
}
.plone-modal-open {
  padding-right: 0;
}
.plone-modal-open > *:not(.plone-modal-wrapper,.select2-drop, .mce-floatpanel) {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}
.picker--time {
  min-width: 256px;
  max-width: 320px;
}
#content .picker--opened .picker__holder {
  opacity: 1;
}
.picker__holder {
  width: 180px;
  margin: -1px 0 0 0;
  border: 1px solid #ccc;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 300px;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
}
.picker__wrap {
  margin: 0;
  /* overrides -1px used to get rid of unecessary scrollbar */
}
.picker__list-item:hover {
  background: #f0f0f0;
  color: #4d4d4d;
}
.picker__list-item--highlighted:hover,
.picker--focused .picker__list-item--highlighted {
  background: #007bb1;
  color: #fff;
}
li.picker__list-item {
  padding: 6px 12px;
  cursor: pointer;
  color: #696969;
}
ul.picker__list {
  background: #fff;
  list-style: none;
  margin: 0;
  padding: 0;
}
.picker__table {
  width: 100%;
  text-align: center;
}
.picker__table th {
  text-align: center;
}
.picker__header {
  text-align: center;
  position: relative;
}
.picker__nav--next,
.picker__nav--prev {
  position: absolute;
  top: 0.5em;
}
.picker__select--month,
.picker__select--year {
  width: 35%;
  display: inline-block;
  margin: 0 6px;
  font-size: 1em;
}
.picker__day {
  font-weight: 400;
}
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  cursor: pointer;
  background: #f0f0f0;
}
.picker__day--highlighted:hover,
.picker__day--highlighted {
  background: #007bb1;
  color: #fff;
  font-weight: 600;
}
.picker__day--outfocus {
  color: #cccccc;
}
.pat-sortable > *:hover {
  cursor: move;
  cursor: -webkit-grab;
  background: #FFF5CD;
}
.pat-sortable .item-dragging,
.pat-sortable .item-dragging:hover {
  cursor: -webkit-grabbing !important;
  background: #f2f2f2;
}
.item-dragging.dragging {
  background: #fea;
  cursor: -webkit-grabbing !important;
  padding: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.17);
}
.pat-tablesorter th:hover {
  color: #007bb1;
}
.pat-tablesorter .sortdirection {
  font-size: 75%;
  opacity: 0.5;
  position: relative;
  top: -1px;
  padding: 2px;
  min-width: 15px;
  display: inline-block;
}
.tooltips {
  opacity: 0;
  transition: opacity 0.2s ease 0.5s;
  position: absolute;
  background: #333333;
  background: rgba(0, 0, 0, 0.9);
  color: #fafafa;
  font-size: 12px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.17);
  max-width: 200px;
  margin-left: -100px;
  left: 200px;
  text-align: center;
  padding: 6px;
}
.tooltips.active {
  opacity: 1;
  transition: opacity 0.2s ease 0.5s;
}
.jqtree-tree {
  list-style: none outside;
  margin-bottom: 0;
  padding: 0;
  padding-left: 18px;
}
.jqtree-tree .jqtree-toggler {
  left: -18px;
  font-size: 75%;
  top: 30%;
  position: absolute;
}
.jqtree-tree .jqtree-element {
  padding: 6px;
}
.jqtree-tree .jqtree-element:hover {
  background: #f0f0f0;
}
.jqtree-tree .jqtree-selected > .jqtree-element {
  background: #cccccc;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle:focus {
  outline: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: unset;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 500;
  line-height: 1.42857143;
  color: #4d4d4d;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #404040;
  background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #004665;
  text-decoration: none;
  outline: 0;
  background-color: #007bb1;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #777777;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}
.open > .dropdown-menu {
  display: block;
}
.open > a {
  outline: 0;
}
.dropdown-menu-right {
  left: auto;
  right: 0;
}
.dropdown-menu-left {
  left: 0;
  right: auto;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777777;
  white-space: nowrap;
}
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}
/* our custom styles */
.custom-theme-marker {
  display: none;
  position: absolute;
  top: 300px;
  left: 30%;
  margin: auto 0;
  transform: rotate(-20deg);
  background: #00ECFF;
  color: #fff;
  padding: 1em;
  z-index: 100;
}
.section-front-page .custom-theme-marker {
  display: block;
}
/* TinyMCE templates */
.image-grid-2x2 {
  padding: 0;
  margin: 0 0 30px 0;
  list-style: none;
  margin-left: -15px;
  margin-right: -15px;
}
.image-grid-2x2 > li {
  float: left;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .image-grid-2x2 > li {
    float: left;
    width: 50%;
  }
}
.image-grid-2x2 > li img {
  max-width: 100%;
  max-height: 100%;
}
body.template-adoptionrulings #content #adoptionrulings em.highlight {
  background-color: yellow;
  font-style: normal;
  padding: 0px;
  color: inherit;
  font-size: inherit;
}
body.template-adoptionrulings #content #adoptionrulings em.highlight:before {
  content: none;
}
body.template-adoptionrulings #content #adoptionrulings ul#innerwrap {
  display: table;
  width: 100%;
}
body.template-adoptionrulings #content #adoptionrulings ul#innerwrap li {
  max-width: initial;
  display: table-cell;
  width: initial;
}
body.template-adoptionrulings #content #adoptionrulings ul#innerwrap li:before {
  content: none;
}
body.template-adoptionrulings #content #adoptionrulings ul#innerwrap li .filter-phase-info {
  float: none;
  display: inline-block;
  margin-left: 6px;
  width: 20px;
  height: 20px;
}
body.template-adoptionrulings #content #adoptionrulings #phase1 .fa-chevron-down {
  margin-left: 2px;
  padding-top: 2px;
}
body.template-adoptionrulings #content #adoptionrulings li#filter-clear-btn:before {
  content: none;
}
body.template-adoptionrulings #content #adoptionrulings li#filter-clear-btn {
  display: table-cell;
}
body.template-adoptionrulings #content #adoptionrulings .search-result-header .row {
  display: block;
  padding: 0px 10px;
}
body.template-adoptionrulings #content #adoptionrulings #top-filter-dropdown {
  position: absolute;
  z-index: 9999;
  margin-left: -110px;
  margin-top: 50px;
}
body.template-adoptionrulings #content #adoptionrulings #top-filter-dropdown input[type="checkbox"] {
  margin-right: 4px;
}
body.template-adoptionrulings #content #pagination ul.notextselect li:before {
  content: none;
}
form[action$='@@data'] table.listing {
  z-index: 9999;
  position: relative;
  background-color: #f9f9f9;
}
body.template-setup-two-factor-authentication #form-widgets-qr_code {
  display: none;
}
body.portaltype-easyform .fieldPreview .fieldControls {
  text-align: right;
  position: initial;
}
body.portaltype-easyform span.named-file-widget.namedblobfile-field label {
  display: inline;
  margin-left: 15px;
  font-size: inherit;
  font-weight: normal;
}
body.portaltype-easyform span.named-file-widget.namedblobfile-field input[type=radio]:after {
  left: 0px;
}
a.skip-main {
  top: 4px;
  left: -9999em;
  padding: 2em;
  line-height: 0;
  text-decoration: none;
  text-transform: none;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  background-color: #000;
  position: absolute !important;
  z-index: 1000;
  color: #fff;
}
a.skip-main:focus {
  left: 5px;
}
body:not(.section-front-page) #viewlet-below-content-body .documentActions,
#viewlet-below-content-body .last-updated {
  display: none;
}
#content .documentByLine.documentByLineExtra {
  font-size: 0.86em;
  color: #72787c;
  font-style: italic;
  margin: 4px 0;
}
h1.section-header-wo-image {
  margin-top: 2.5rem;
}
.managedPortlet .portlet {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}
.managedPortlet .portlet > :first-child {
  border-radius: 4px 4px 0 0;
}
.managedPortlet .portlet > :last-child {
  border-radius: 0 0 4px 4px;
}
.managedPortlet .portlet .portletHeader {
  background: #f2f1f1;
  font-weight: 500;
  font-size: 18px;
  padding: 13px 15px;
  color: #696969;
}
.managedPortlet .portlet .portletHeader > a {
  color: #086ca3;
}
.managedPortlet .portlet.portletCalendar .portletHeader {
  text-align: center;
  position: relative;
}
.managedPortlet .portlet.portletCalendar .portletHeader .calendarPrevious,
.managedPortlet .portlet.portletCalendar .portletHeader .calendarNext {
  width: 30px;
  height: 30px;
  padding: 0 1px 2px;
  border-radius: 50%;
  position: absolute;
  display: inline-block;
  left: 5px;
  font-size: 24px;
  margin-top: -3px;
  line-height: 28px;
  font-weight: 500;
}
.managedPortlet .portlet.portletCalendar .portletHeader .calendarPrevious:hover,
.managedPortlet .portlet.portletCalendar .portletHeader .calendarNext:hover {
  background: #fff;
  text-decoration: none;
}
.managedPortlet .portlet.portletCalendar .portletHeader .calendarNext {
  right: 5px;
  left: inherit;
}
.managedPortlet .portlet .portletContent {
  font-size: 14px;
  border-top: 1px solid #e5e5e5;
  background: #fff;
  font-weight: 400;
}
.managedPortlet .portlet .portletContent > *,
.managedPortlet .portlet .portletContent > div:not(.portalMessage) {
  padding: 10px 15px;
  margin: 0;
  color: #696969;
}
.managedPortlet .portlet .portletContent > ul {
  padding: 0;
  list-style: none;
  position: relative;
}
.managedPortlet .portlet .portletContent > ul > li a {
  display: block;
  padding: 10px 15px 26px 30px;
  position: relative;
  z-index: 1;
  color: #086ca3;
}
.managedPortlet .portlet .portletContent > ul > li > a {
  border-top: 1px solid #e5e5e5;
}
.managedPortlet .portlet .portletContent > ul > li:first-child > a {
  border-top: 0;
}
.managedPortlet .portlet .portletContent > ul ul {
  padding-left: 15px;
  list-style: none;
}
.managedPortlet .portlet .portletContent > ul .portletItemDetails {
  display: block;
  z-index: 0;
  padding: 0 15px 10px 30px;
  font-weight: 400;
  position: relative;
  margin-top: -26px;
  font-size: 12px;
  color: #696969;
}
.managedPortlet .portlet .portletContent > ul li:hover {
  background: #fcfcfd;
}
.managedPortlet .portlet .portletContent > ul li:hover a::before {
  color: #64bee8;
}
.managedPortlet .portlet.portletCalendar table {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  border-collapse: collapse;
  border-spacing: 0;
}
.managedPortlet .portlet.portletCalendar table tr {
  line-height: 30px;
}
.managedPortlet .portlet.portletCalendar table thead {
  background: #fcfcfd;
  border-bottom: 1px solid #e5e5e5;
}
.managedPortlet .portlet.portletCalendar table thead th {
  color: #696969;
  font-weight: 300;
  height: 40px;
  text-align: center;
}
.managedPortlet .portlet.portletCalendar table td > * {
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.managedPortlet .portlet.portletCalendar table .cal_has_events {
  position: relative;
}
.managedPortlet .portlet.portletCalendar table .cal_has_events a:hover {
  background: #dbecfe;
  text-decoration: none;
  box-shadow: 0 0 0 5px #dbecfe;
}
.managedPortlet .portlet.portletCalendar table .cal_has_events a::after {
  content: "•";
  position: absolute;
  margin-left: 50%;
  left: -3px;
  font-size: 20px;
  color: #a7daf2;
  margin-top: 9px;
}
.managedPortlet .portlet.portletCalendar table .cal_has_events a:hover::after {
  color: #64bee8;
}
.managedPortlet .portlet.portletCalendar table .cal_next_month,
.managedPortlet .portlet.portletCalendar table .cal_prev_month {
  color: #000;
}
.managedPortlet .portlet.portletCalendar table .cal_next_month.cal_has_events > a,
.managedPortlet .portlet.portletCalendar table .cal_prev_month.cal_has_events > a {
  color: #a7daf2;
}
.managedPortlet .portlet.portletCalendar table .cal_next_month.cal_has_events > a::after,
.managedPortlet .portlet.portletCalendar table .cal_prev_month.cal_has_events > a::after {
  color: #d3edf8;
}
.managedPortlet .portlet.portletCalendar table .cal_next_month.cal_has_events > a:hover,
.managedPortlet .portlet.portletCalendar table .cal_prev_month.cal_has_events > a:hover {
  color: #004665;
}
.managedPortlet .portlet.portletCalendar table .cal_next_month.cal_has_events > a:hover::after,
.managedPortlet .portlet.portletCalendar table .cal_prev_month.cal_has_events > a:hover::after {
  color: #64bee8;
}
.managedPortlet .portlet.portletCalendar table .today > * {
  background: #e5e5e5;
  font-weight: 500;
  box-shadow: 0 0 0 5px #e5e5e5;
  color: #000;
}
.managedPortlet .portlet.portletCalendar table tbody tr:first-child td > * {
  margin-top: 10px;
}
.managedPortlet .portlet.portletCalendar table tbody tr:last-child td > * {
  margin-bottom: 10px;
}
.managedPortlet .portlet .formControls {
  border: 0;
  background: none;
  padding: 0;
  box-shadow: none;
}
.managedPortlet .portlet .portletFooter {
  border-top: 1px solid #e5e5e5;
  background: #fcfcfd;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 400;
  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.05);
}
#upgrade-products .configlets li,
#install-products .configlets li,
#activated-products .configlets li,
#broken-products .configlets li {
  padding: 12px;
  border-top: 1px dotted #e5e5e5;
}
#upgrade-products .configlets li:first-child,
#install-products .configlets li:first-child,
#activated-products .configlets li:first-child,
#broken-products .configlets li:first-child {
  border-top: 0;
}
#upgrade-products .configlets h3,
#install-products .configlets h3,
#activated-products .configlets h3,
#broken-products .configlets h3,
#upgrade-products .configlets p,
#install-products .configlets p,
#activated-products .configlets p,
#broken-products .configlets p {
  margin: 0;
}
#upgrade-products .configlets .portletContent:last-child,
#install-products .configlets .portletContent:last-child,
#activated-products .configlets .portletContent:last-child,
#broken-products .configlets .portletContent:last-child {
  margin-bottom: 0;
}
#upgrade-products .configlets .pull-right,
#install-products .configlets .pull-right,
#activated-products .configlets .pull-right,
#broken-products .configlets .pull-right {
  margin: 0 0 12px 12px;
}
.switch-portlet-manager {
  margin-top: 0.5em;
  width: auto;
}
.managedPortlet .portletHeader {
  font-weight: 300;
}
.managedPortlet.inheritedPortlet {
  margin: 5px 15px;
}
.managedPortlet.inheritedPortlet .portletHeader {
  padding: 3px 15px;
}
.managedPortlet.blockedPortlet {
  opacity: 0.7;
}
.managedPortletActions {
  float: right;
  margin-top: -6px;
}
.managedPortletActions form {
  display: inline-block;
}
.alert.status,
.portalMessage {
  padding: 15px;
  padding-left: 45px;
  margin-bottom: 20px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
  font-size: 14px;
  font-weight: 400;
  text-shadow: 0 1px rgba(255, 255, 255, 0.1);
}
.alert.status > strong,
.portalMessage > strong,
.alert.status > dt,
.portalMessage > dt {
  font-weight: 700;
  padding-right: 15px/3;
  color: rgba(0, 0, 0, 0.5);
}
.alert.status a,
.portalMessage a {
  font-weight: 400;
  color: #005074;
}
.alert.status > strong::before,
.portalMessage > strong::before,
.alert.status > dt::before,
.portalMessage > dt::before {
  font-size: 14px;
  font-weight: 400;
  margin-left: -30px;
  margin-right: 10px;
  display: inline-block;
  border-radius: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.5);
  top: -1px;
  position: relative;
}
.alert.status > dt,
.portalMessage > dt,
.alert.status > dd,
.portalMessage > dd {
  display: inline;
}
.alert.status > dd,
.portalMessage > dd {
  margin: 0;
}
.alert.status.alert-none,
.portalMessage.alert-none {
  box-shadow: none;
}
.alert.status.alert-success,
.portalMessage.alert-success,
.alert.status.info,
.portalMessage.info {
  background-color: #aad6ea;
  border: 1px solid #9acee6;
  color: rgba(0, 0, 0, 0.5);
}
.alert.status.alert-success > strong,
.portalMessage.alert-success > strong,
.alert.status.info > strong,
.portalMessage.info > strong,
.alert.status.alert-success > dt,
.portalMessage.alert-success > dt,
.alert.status.info > dt,
.portalMessage.info > dt {
  color: rgba(0, 0, 0, 0.67);
}
.alert.status.alert-success > strong::before,
.portalMessage.alert-success > strong::before,
.alert.status.info > strong::before,
.portalMessage.info > strong::before,
.alert.status.alert-success > dt::before,
.portalMessage.alert-success > dt::before,
.alert.status.info > dt::before,
.portalMessage.info > dt::before {
  background-color: #3e7b91;
  content: "i";
}
.alert.status.alert-warning,
.portalMessage.alert-warning,
.alert.status.warning,
.portalMessage.warning {
  background-color: #ebd380;
  border: 1px solid #e7ca66;
  color: rgba(0, 0, 0, 0.5);
}
.alert.status.alert-warning > strong,
.portalMessage.alert-warning > strong,
.alert.status.warning > strong,
.portalMessage.warning > strong,
.alert.status.alert-warning > dt,
.portalMessage.alert-warning > dt,
.alert.status.warning > dt,
.portalMessage.warning > dt {
  color: rgba(0, 0, 0, 0.67);
}
.alert.status.alert-warning > strong::before,
.portalMessage.alert-warning > strong::before,
.alert.status.warning > strong::before,
.portalMessage.warning > strong::before,
.alert.status.alert-warning > dt::before,
.portalMessage.alert-warning > dt::before,
.alert.status.warning > dt::before,
.portalMessage.warning > dt::before {
  background-color: #bc8d0d;
  content: "!";
}
.alert.status.alert-error,
.portalMessage.alert-error,
.alert.status.error,
.portalMessage.error {
  background-color: #EC9C8D;
  border: 1px solid #eb9484;
  color: rgba(0, 0, 0, 0.5);
}
.alert.status.alert-error > strong,
.portalMessage.alert-error > strong,
.alert.status.error > strong,
.portalMessage.error > strong,
.alert.status.alert-error > dt,
.portalMessage.alert-error > dt,
.alert.status.error > dt,
.portalMessage.error > dt {
  color: rgba(0, 0, 0, 0.67);
}
.alert.status.alert-error > strong::before,
.portalMessage.alert-error > strong::before,
.alert.status.error > strong::before,
.portalMessage.error > strong::before,
.alert.status.alert-error > dt::before,
.portalMessage.alert-error > dt::before,
.alert.status.error > dt::before,
.portalMessage.error > dt::before {
  background-color: #ac493f;
  content: "×";
}
.portlet .portletContent > .portalMessage {
  padding: 15px;
  padding-left: 45px;
  margin: 15px;
}
/* special octogonal shape for error
.alert.status.alert-error,
.portalMessage.error {
	& > strong::before, & > dt::before {
		border-radius: 8px; //soften border
		background: @plone-alert-error-bullet;
		background:
			linear-gradient(135deg, transparent 4px, @plone-alert-error-bullet 0) top left,
			linear-gradient(225deg, transparent 4px, @plone-alert-error-bullet 0) top right,
			linear-gradient(315deg, transparent 4px, @plone-alert-error-bullet 0) bottom right,
			linear-gradient(45deg,  transparent 4px, @plone-alert-error-bullet 0) bottom left;
		background-size: 50% 50%;
		background-repeat: no-repeat;
	}
}
*/
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxk.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVew8.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkxhjaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkyFjaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4n.ttf) format('truetype');
}
/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --content-max-width: 951px;
  --grid-column: 7.1428vw;
  /* calc(100vw / 14) */
  --grid-row: 7.1428vh;
  /* calc(100vh / 14) */
  --grid-gutter: 10px;
  --color-black: #111111;
  --color-gray-dark: #1e1e1e;
  --color-white: #ffffff;
  --color-broken-white: #f1f3f5;
  --color-dark-blue: #334f59;
  --color-petroleum: #2e526f;
  --color-light-petroleum: #698592;
  --color-header-blue: #243847;
  --color-gray: #e9ecef;
  --color-light-gray: #f1f3f5;
  --color-bluegray: #4d708f;
  --color-light-blue: #3e9dc1;
  --color-red: #b83f3f;
  --color-dark-red: #952d2d;
  --font-lato: "Lato", serif, sans-serif;
  --font-open: "Open Sans", serif, sans-serif;
  --font-size-small: 12px;
  --font-size-body: 14px;
  --font-size-body-large: 18px;
  --font-size-menu: 15px;
  --font-size-header-small-mobile: 1.15rem;
  --font-size-header-medium-mobile: 18px;
  --font-size-header-large-mobile: 24px;
  --font-size-header-small: 16px;
  --font-size-header-medium: 20px;
  --font-size-header-large: 36px;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-demibold: 600;
  --font-weight-bold: 700;
  --font-style-italic: italic;
  --font-lineheight-body: 22px;
  --font-lineheight-body-large: 24px;
  --font-lineheight-small: 18px;
  --font-lineheight-menu: 26px;
  --font-lineheight-small-header: 20px;
  --font-lineheight-medium-header: 28px;
  --font-lineheight-large-header: 40px;
  --font-lineheight-headers: 1em;
}
/* Uncomment and set these variables to customize the grid. */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}
.row {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}
.row.reverse {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}
.col.reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
}
.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}
.col-xs {
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}
.col-xs-1 {
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%;
}
.col-xs-2 {
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%;
}
.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}
.col-xs-4 {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%;
}
.col-xs-5 {
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%;
}
.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}
.col-xs-7 {
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%;
}
.col-xs-8 {
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%;
}
.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}
.col-xs-10 {
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%;
}
.col-xs-11 {
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%;
}
.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
.col-xs-offset-1 {
  margin-left: 8.333%;
}
.col-xs-offset-2 {
  margin-left: 16.667%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-4 {
  margin-left: 33.333%;
}
.col-xs-offset-5 {
  margin-left: 41.667%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-7 {
  margin-left: 58.333%;
}
.col-xs-offset-8 {
  margin-left: 66.667%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-10 {
  margin-left: 83.333%;
}
.col-xs-offset-11 {
  margin-left: 91.667%;
}
[dir="ltr"] .start-xs {
  text-align: left;
}
[dir="rtl"] .start-xs {
  text-align: right;
}
.start-xs {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.center-xs {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
}
[dir="ltr"] .end-xs {
  text-align: right;
}
[dir="rtl"] .end-xs {
  text-align: left;
}
.end-xs {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.top-xs {
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}
.middle-xs {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
.bottom-xs {
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}
.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.between-xs {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.first-xs {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1;
}
.last-xs {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}
@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
  }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .col-sm {
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }
  .col-sm-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }
  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  .col-sm-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }
  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }
  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  .col-sm-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }
  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-1 {
    margin-left: 8.333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.667%;
  }
  [dir="ltr"] .start-sm {
    text-align: left;
  }
  [dir="rtl"] .start-sm {
    text-align: right;
  }
  .start-sm {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .center-sm {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }
  [dir="ltr"] .end-sm {
    text-align: right;
  }
  [dir="rtl"] .end-sm {
    text-align: left;
  }
  .end-sm {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
  }
  .top-sm {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }
  .middle-sm {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }
  .bottom-sm {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }
  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-sm {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .first-sm {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .last-sm {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}
@media only screen and (min-width: 62em) {
  .container {
    width: 61rem;
  }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .col-md {
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }
  .col-md-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }
  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  .col-md-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }
  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }
  .col-md-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  .col-md-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }
  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-1 {
    margin-left: 8.333%;
  }
  .col-md-offset-2 {
    margin-left: 16.667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.333%;
  }
  .col-md-offset-5 {
    margin-left: 41.667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.333%;
  }
  .col-md-offset-8 {
    margin-left: 66.667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.333%;
  }
  .col-md-offset-11 {
    margin-left: 91.667%;
  }
  [dir="ltr"] .start-md {
    text-align: left;
  }
  [dir="rtl"] .start-md {
    text-align: right;
  }
  .start-md {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .center-md {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }
  [dir="ltr"] .end-md {
    text-align: right;
  }
  [dir="rtl"] .end-md {
    text-align: left;
  }
  .end-md {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
  }
  .top-md {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }
  .middle-md {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }
  .bottom-md {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }
  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-md {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .first-md {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .last-md {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}
@media only screen and (min-width: 75em) {
  .container {
    width: 71rem;
  }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .col-lg {
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }
  .col-lg-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }
  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  .col-lg-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }
  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }
  .col-lg-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  .col-lg-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }
  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-1 {
    margin-left: 8.333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.667%;
  }
  [dir="ltr"] .start-lg {
    text-align: left;
  }
  [dir="rtl"] .start-lg {
    text-align: right;
  }
  .start-lg {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .center-lg {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }
  [dir="ltr"] .end-lg {
    text-align: right;
  }
  [dir="rtl"] .end-lg {
    text-align: left;
  }
  .end-lg {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
  }
  .top-lg {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }
  .middle-lg {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }
  .bottom-lg {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }
  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-lg {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .first-lg {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .last-lg {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}
/* ADD XLARGE */
@media only screen and (min-width: 90em) {
  .container {
    width: 91rem;
  }
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-offset-0,
  .col-xl-offset-1,
  .col-xl-offset-2,
  .col-xl-offset-3,
  .col-xl-offset-4,
  .col-xl-offset-5,
  .col-xl-offset-6,
  .col-xl-offset-7,
  .col-xl-offset-8,
  .col-xl-offset-9,
  .col-xl-offset-10,
  .col-xl-offset-11,
  .col-xl-offset-12 {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .col-xl {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-xl-offset-0 {
    margin-left: 0;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  [dir="ltr"] .start-xl {
    text-align: left;
  }
  [dir="rtl"] .start-xl {
    text-align: right;
  }
  .start-xl {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .center-xl {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  [dir="ltr"] .end-xl {
    text-align: right;
  }
  [dir="rtl"] .end-xl {
    text-align: left;
  }
  .end-xl {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .top-xl {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .middle-xl {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bottom-xl {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .around-xl {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-xl {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .first-xl {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .last-xl {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
/* flexboxgrid overrides */
.container-fluid {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  height: 100%;
}
.container {
  max-width: 951px;
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-left: var(--grid-gutter);
  padding-right: 10px;
  padding-right: var(--grid-gutter);
}
@media screen and (min-width: 1024px) {
  .container {
    padding: 0;
  }
}
.inner-container {
  padding: 0;
}
/* WITH GUTTER */
.row {
  margin-right: calc(-1 * 10px);
  margin-right: calc(-1 * var(--grid-gutter));
  margin-left: calc(-1 * 10px);
  margin-left: calc(-1 * var(--grid-gutter));
}
[class*="col-"] {
  padding-right: 10px;
  padding-right: var(--grid-gutter);
  padding-left: 10px;
  padding-left: var(--grid-gutter);
}
html,
body {
  padding: 0;
  margin: 0;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.site-wrapper {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
header {
  height: auto;
}
.template-publication-collection-view #content header {
  padding-bottom: 50px;
}
main {
  height: auto;
}
section {
  margin: 0 0 30px 0;
}
img {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto;
}
img.image-left {
  float: left;
  margin: 0.5em 1em 0.5em 0;
}
img.image-right {
  float: right;
  margin: 0.5em;
}
#portal-column-one:first-child,
#portal-column-two:first-child {
  padding-top: 42px;
}
@media print {
  footer,
  #portal-column-one,
  #portal-column-two,
  #edit-zone,
  .breadcrumb-container {
    display: none;
  }
}
.contenttype-easyform.contenttype-easyform::before {
  content: none;
}
.eventItemData {
  background-color: #243747;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
  border-bottom: 2px solid #ffffff;
  -webkit-box-shadow: 0 1px #959a9d;
  box-shadow: 0 1px #959a9d;
  color: #ffffff;
  height: 70px;
  float: left;
  margin: 0 20px 0 0;
  text-align: center;
  width: 60px;
}
.eventItemDay {
  display: block;
  font-size: 36px;
  line-height: 1;
  margin: 10px 0 0;
}
.eventItemMon {
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
}
div#content-core {
  padding-bottom: 20px;
}
#content .eventDetails table tr td a {
  font-size: 14px;
}
#content .eventDetails {
  margin-bottom: 20px;
}
.factBox {
  background: #087b8c;
  color: white;
  margin-bottom: 18px;
  padding: 10px;
}
.factBox h2 {
  font-size: var(--font-size-body-large);
  line-height: 20px;
}
#content .factBox a {
  color: var(--color-white);
  font-weight: 700;
}
#content .factBox a:hover {
  color: var(--color-black);
}
#content .factBox a:visited {
  color: var(--color-white);
}
html,
body {
  font-family: "Lato", serif, sans-serif;
  font-family: var(--font-lato);
  font-size: 14px;
  font-size: var(--font-size-body);
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  line-height: 22px;
  line-height: var(--font-lineheight-body);
  background-color: #f1f3f5;
  background-color: var(--color-light-gray);
  color: #2e526f;
  color: var(--color-petroleum);
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Headers */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.5em 0;
  line-height: 1em;
  line-height: var(--font-lineheight-headers);
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
h1[class*="secondary"],
h2[class*="secondary"],
h3[class*="secondary"],
h4[class*="secondary"],
h5[class*="secondary"],
h6[class*="secondary"] {
  font-weight: 300;
  font-weight: var(--font-weight-light);
}
h1 {
  font-size: 24px;
  font-size: var(--font-size-header-large-mobile);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 36px;
    font-size: var(--font-size-header-large);
  }
}
h2 {
  font-size: 18px;
  font-size: var(--font-size-header-medium-mobile);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 20px;
    font-size: var(--font-size-header-medium);
  }
}
h3 {
  font-size: 1.15rem;
  font-size: var(--font-size-header-small-mobile);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 16px;
    font-size: var(--font-size-header-small);
  }
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
b,
strong {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
small {
  display: block;
  font-size: 12px;
  font-size: var(--font-size-small);
  line-height: 22px;
  line-height: var(--font-lineheight-body);
}
small * {
  font-size: 12px;
  font-size: var(--font-size-small);
  line-height: 22px;
  line-height: var(--font-lineheight-body);
}
a {
  color: currentColor;
}
.link {
  background-color: rgba(255, 0, 0, 0.2);
  padding: 0.25rem;
  color: red;
}
ul,
ol {
  margin-top: 0;
  padding-left: 1em;
}
address {
  font-style: normal;
}
hr {
  border: none;
  background-color: currentColor;
  color: currentColor;
  height: 1px;
  margin: 0 0 1rem 0;
}
::-moz-selection {
  color: var(--color-primary);
  background: #1e1e1e;
  background: var(--color-gray-dark);
}
::selection {
  color: var(--color-primary);
  background: #1e1e1e;
  background: var(--color-gray-dark);
}
dt > a {
  font-size: 16px;
  font-size: var(--font-size-header-small);
  display: block;
  padding-bottom: 10px;
}
dd {
  margin-inline-start: 0;
}
dd.portletFooter {
  margin-top: 10px;
}
.button {
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  background-color: #ffffff;
  background-color: var(--color-white);
  color: #1e1e1e;
  color: var(--color-gray-dark);
  font-family: var(--font-hill);
  font-size: 12px;
  font-size: var(--font-size-small);
  font-weight: 600;
  font-weight: var(--font-weight-demibold);
  line-height: 1em;
  line-height: var(--font-lineheight-headers);
  border: 1px solid #1e1e1e;
  border: 1px solid var(--color-gray-dark);
  border-radius: 1rem;
  padding: 3px 1rem 0 1rem;
  height: 2rem;
}
.button.secondary {
  background-color: transparent;
  border: 1px solid currentColor;
  color: currentColor;
}
.button svg + span {
  margin-left: 0.5rem;
}
.button span + svg {
  margin-left: 0.5rem;
}
.button[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
.button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -1rem;
}
.button-group .button {
  margin-bottom: 1rem;
}
.button-group .button:not(:last-child) {
  margin-right: 1rem;
}
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
legend {
  padding: 0;
}
label {
  font-size: 12px;
  font-size: var(--font-size-small);
  margin-bottom: 5px;
  display: block;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
textarea {
  display: block;
  font-family: "Lato", serif, sans-serif;
  font-family: var(--font-lato);
  font-size: 14px;
  font-size: var(--font-size-body);
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  line-height: 22px;
  line-height: var(--font-lineheight-body);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"])::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: currentColor;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"])::-moz-placeholder,
textarea::-moz-placeholder {
  color: currentColor;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: currentColor;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"])::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: currentColor;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"])::placeholder,
textarea::placeholder {
  color: currentColor;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"])::-moz-selection,
textarea::-moz-selection {
  color: #ffffff;
  color: var(--color-white);
  background: #1e1e1e;
  background: var(--color-gray-dark);
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"])::selection,
textarea::selection {
  color: #ffffff;
  color: var(--color-white);
  background: #1e1e1e;
  background: var(--color-gray-dark);
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0.5;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  opacity: 0.5;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0.5;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):focus::-ms-input-placeholder,
textarea:focus::-ms-input-placeholder {
  opacity: 0.5;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):focus::placeholder,
textarea:focus::placeholder {
  opacity: 0.5;
}
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]) + input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]) textarea,
textarea + input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
textarea textarea {
  margin-top: -1rem;
}
textarea {
  border: 1px solid currentColor;
  padding: 1rem 0.5rem;
  font-size: 14px;
  font-size: var(--font-size-body);
  line-height: 22px;
  line-height: var(--font-lineheight-body);
  border-radius: 0;
  resize: vertical;
}
.checkbox {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 12px;
  font-size: var(--font-size-small);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox:hover input ~ .checkmark {
  opacity: 1;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox input:checked ~ .checklabel {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
.checkbox input:checked ~ .checkmark {
  opacity: 1;
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checklabel {
  font-size: 12px;
  font-size: var(--font-size-small);
}
.checkbox .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #ffffff;
  border: 1px solid var(--color-white);
  opacity: 0.5;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 0;
  width: 7px;
  height: 10px;
  border: solid #ffffff;
  border: solid var(--color-white);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
svg {
  width: 25px;
  height: 25px;
}
.svg-arrow {
  width: 20px;
  height: 30px;
}
header {
  background-color: #ffffff;
  background-color: var(--color-white);
  color: #ffffff;
  color: var(--color-white);
}
header .header-wrapper {
  width: 100%;
}
header .logo {
  margin: 13px 0;
  display: inline-block;
}
@media screen and (min-width: 1024px) {
  header .logo {
    margin: 13px 0 16px;
  }
}
header nav {
  position: relative;
  padding: 20px 0;
}
@media screen and (min-width: 1024px) {
  header nav {
    padding: 20px 0 10px;
  }
}
header .nav-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
@media screen and (min-width: 1024px) {
  header .nav-wrapper {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
@media print {
  header .nav-wrapper {
    display: none;
  }
}
header .search-container {
  height: 25px;
  margin-top: 6px;
  margin-left: 42px;
}
header .search-container .search-icon {
  opacity: 0.99;
}
header .search-container input[type="checkbox"] {
  display: block;
  width: 25px;
  height: 25px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  /* hide this */
  z-index: 2;
}
header .search-container input[type="checkbox"]:checked {
  z-index: 101;
  left: calc(88% - 10px);
  top: 15px;
}
@media screen and (min-width: 1024px) {
  header .search-container input[type="checkbox"]:checked {
    left: calc(88% - 30px);
  }
}
header .search-container input[type="checkbox"]:checked ~ .search-icon {
  opacity: 0;
}
header .search-container input[type="checkbox"]:checked ~ .search-overlay {
  -webkit-transform: none;
  transform: none;
}
header .menulink {
  font-size: 18px;
  font-size: var(--font-size-body-large);
  color: #ffffff;
  color: var(--color-white);
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-weight: 600;
  font-weight: var(--font-weight-demibold);
  -webkit-transition: color 0.2s ease-in;
  transition: color 0.2s ease-in;
  text-decoration: none;
}
@media screen and (min-width: 1024px) {
  header .menulink {
    color: #334f59;
    color: var(--color-dark-blue);
    font-size: 15px;
    font-size: var(--font-size-menu);
    line-height: 26px;
    line-height: var(--font-lineheight-menu);
  }
}
header .menulink:hover {
  text-decoration: underline;
  color: #b83f3f;
  color: var(--color-red);
}
.menuText {
  font-size: 16px;
  font-size: var(--font-size-header-small);
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-weight: 600;
  font-weight: var(--font-weight-demibold);
  color: #4d708f;
  color: var(--color-bluegray);
  position: absolute;
  left: 45px;
}
@media screen and (min-width: 1024px) {
  .menuText {
    display: none;
  }
}
.menuToggle {
  display: block;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  /* hide this */
  z-index: 2;
  /* and place it over the hamburger */
  -webkit-touch-callout: none;
}
@media screen and (min-width: 1024px) {
  .menuToggle input {
    display: none;
  }
}
/*
 * Just a quick hamburger
 */
.menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #4d708f;
  background: var(--color-bluegray);
  border-radius: 3px;
  z-index: 1;
  -webkit-transform-origin: 4px 0px;
  transform-origin: 4px 0px;
  -webkit-transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
@media screen and (min-width: 1024px) {
  .menuToggle span {
    display: none;
  }
}
.menuToggle span:first-child {
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}
.menuToggle span:nth-last-child(2) {
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}
.menuToggle input:checked ~ span {
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
  transform: rotate(45deg) translate(-2px, -1px);
  background: #334f59;
  background: var(--color-dark-blue);
}
.menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
  transform: rotate(0deg) scale(0.2, 0.2);
}
.menuToggle input:checked ~ span:nth-last-child(2) {
  -webkit-transform: rotate(-45deg) translate(0, -1px);
  transform: rotate(-45deg) translate(0, -1px);
}
.menu {
  position: absolute;
  top: -20px;
  left: -10px;
  height: 100vh;
  width: 100vw;
  background: #698592;
  background: var(--color-light-petroleum);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  padding-top: 50px;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.menu li {
  padding: 10px 0;
}
@media screen and (min-width: 768px) {
  .menu {
    left: calc(((100vw - 46rem + 20px) / 2) * -1);
    padding-left: calc((100vw - 46rem + 20px) / 2);
  }
}
@media screen and (min-width: 1024px) {
  .menu {
    height: auto;
    position: relative;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    background-color: transparent;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .menu li {
    padding: 0;
  }
  .menu li:not(:first-child) {
    margin-left: 42px;
  }
}
/*
 * And let's slide it in from the left
 */
.menuToggle input:checked ~ ul {
  -webkit-transform: none;
  transform: none;
}
.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
@media print {
  .search-overlay {
    display: none;
  }
}
.search-overlay .inner-container {
  position: relative;
}
.search-overlay input {
  min-height: 50px;
  border-radius: 10px;
  opacity: 1;
  border: none;
  padding: 14px 54px;
  width: 88%;
  position: absolute;
  top: 86px;
  left: 25px;
}
@media screen and (min-width: 1024px) {
  .search-overlay input {
    left: 0;
  }
}
.search-overlay .search-submit {
  position: absolute;
  top: 93px;
  border: none;
  background: transparent;
  cursor: pointer;
  left: 25px;
}
@media screen and (min-width: 1024px) {
  .search-overlay .search-submit {
    left: 0;
  }
}
.search-overlay .close {
  position: absolute;
  top: 98px;
  height: 25px;
  border: none;
  background: transparent;
  cursor: pointer;
  left: calc(88% - 10px);
}
@media screen and (min-width: 1024px) {
  .search-overlay .close {
    left: calc(88% - 36px);
  }
}
#cludo-search-form .search_autocomplete ul {
  top: 136px;
}
#cludo-search-form button {
  -webkit-box-shadow: none;
  box-shadow: none;
}
#cludo-search-form button:hover {
  background-color: transparent;
}
#content #cludo-search-results li:before {
  content: none;
}
#content #cludo-search-results #cludo-back-to-top {
  position: relative;
}
footer {
  background: #4d708f;
  background: var(--color-bluegray);
  color: #f1f3f5;
  color: var(--color-broken-white);
}
.footer-wrapper {
  padding: 79px 0 59px;
}
@media screen and (min-width: 768px) {
  .footer-wrapper {
    padding: 99px 0 79px;
  }
}
.footer-wrapper ul {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
}
.footer-wrapper a {
  text-decoration: none;
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-size: 16px;
  font-size: var(--font-size-header-small);
  line-height: 32px;
  letter-spacing: 0;
}
.footer-wrapper a:hover {
  text-decoration: underline;
}
address {
  padding: 22px 0 20px;
}
address .col-xs-12 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@media screen and (min-width: 768px) {
  address .col-xs-12 {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
address p {
  font-size: 12px;
  font-size: var(--font-size-small);
  line-height: 18px;
  line-height: var(--font-lineheight-small);
  display: inline-block;
  margin-bottom: 10px;
}
address a {
  text-decoration: none;
}
address a:hover {
  text-decoration: underline;
}
.theme-green.example-component {
  color: green;
}
.theme-red.example-component {
  color: red;
}
.example-component {
  color: gray;
}
.user-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.user-item {
  background: #111111;
  background: var(--color-black);
  color: white;
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 0.25rem;
}
.breadcrumb-container {
  background-color: #e9ecef;
  background-color: var(--color-gray);
  margin-bottom: 3px;
}
.breadcrumb-container .row {
  position: relative;
}
.breadcrumb-list {
  padding: 18px 0 15px;
  margin: 0 0 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: calc(100% - 40px);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.breadcrumb-list li {
  padding: 0px;
  color: #2e526f;
  color: var(--color-petroleum);
}
.breadcrumb-list li:not(:first-child)::before {
  content: "-";
  color: currentcolor;
  padding: 0 10px;
  font-size: 14px;
  font-size: var(--font-size-body);
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-weight: 600;
  font-weight: var(--font-weight-demibold);
  position: absolute;
  width: 10px;
}
.breadcrumb-list li:first-child .breadcrumb {
  padding-left: 0;
}
.breadcrumb,
#breadcrumbs-current {
  color: #2e526f;
  color: var(--color-petroleum);
  text-decoration: none;
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-weight: 600;
  font-weight: var(--font-weight-demibold);
  padding-left: 25px;
}
.breadcrumb:hover,
#breadcrumbs-current:hover {
  text-decoration: underline;
}
#breadcrumbs-current {
  font-weight: 400;
}
#breadcrumbs-current:hover {
  text-decoration: none;
}
.print-page {
  position: absolute;
  right: 10px;
}
#print-icon a {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAQCAYAAAAbBi9cAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAxOC8xMS8xM+RWh7cAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAABIklEQVQ4ja2TIU8DQRCFv9lsgqAVUMI/AFFR0QAJisDPwHFBIMjVcb6GtI4DQ1LOIpogwGExEASiAoGrJW1FExRhMA1cl707QnnJmrdvv5mdZARHQRSHwKHrOzpLWmEMsHd0AoD1hOrASgGo7ho/QKp6IGLO8yiqH0+uJ0F0ug26lfIGwLCgo0WgApSBeZArC3oNlAoeZukZuAHtGGDujxCAS1U9BtQAbzOAmiIyAipmBkha7/8FwuAfdBfVVe+BridfssAIWHIu+km78eKrHERx32O/WlR3ENlImftANecXVeAe6Hw5qg82aTd6QC9VcQ1YzwEtA49JK7yA713zDXsBGOeAxpPMlHxLq0AtiOLdDFANuP0N6A7YBLIWdzDJTOkT+9RSDm4FXEQAAAAASUVORK5CYII=) no-repeat 50% 50%;
  width: 20px;
  float: right;
  margin-top: -38px;
  color: #e9ecef;
}
.title-image-container {
  padding-top: 36.87%;
  background-image: url("http://placeimg.com/952/351/people");
  background-size: cover;
  background-position: top left;
  position: relative;
}
.title-image-container .content {
  position: absolute;
  top: 50%;
  left: 7%;
  max-width: 86%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #ffffff;
  color: var(--color-white);
  font-family: var(font-open);
}
.title-image-container .content h1 {
  font-size: 20px;
  font-size: var(--font-size-header-medium);
  line-height: 28px;
  line-height: var(--font-lineheight-medium-header);
}
@media screen and (min-width: 768px) {
  .title-image-container .content h1 {
    font-size: 36px;
    font-size: var(--font-size-header-large);
    line-height: 40px;
    line-height: var(--font-lineheight-large-header);
  }
}
.title-image-container .content .manchet {
  font-size: 18px;
  font-size: var(--font-size-body-large);
  line-height: 24px;
  line-height: var(--font-lineheight-body-large);
  margin-bottom: 0;
}
.link-box-container {
  margin-bottom: 20px;
}
.link-box-link {
  background-color: #ffffff;
  background-color: var(--color-white);
  background: #ffffff 0% 0% no-repeat padding-box;
  background: var(--color-white) 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border: 1px solid var(--color-white);
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 48px 46px 48px 36px;
  min-height: 280px;
  cursor: pointer;
  text-decoration: none;
}
.link-box-link:hover {
  border-color: #b83f3f;
  border-color: var(--color-red);
  text-decoration: underline;
}
.link-box-link .title {
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-size: 20px;
  font-size: var(--font-size-header-medium);
  line-height: 28px;
  line-height: var(--font-lineheight-medium-header);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  color: #243847;
  color: var(--color-header-blue);
  position: relative;
  min-height: 60px;
  margin-bottom: 2.5em;
}
.link-box-link .title::after {
  content: "";
  position: absolute;
  left: 0;
  width: 70px;
  height: 5px;
  background-color: #b83f3f;
  background-color: var(--color-red);
  bottom: -20px;
}
.last-updated p {
  font-style: italic;
  color: #5A707C;
}
.hiddenStructure {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: #fff;
  background-color: #000;
}
.portletNavigationTree ul {
  list-style: none;
}
.portletNavigationTree a {
  text-decoration: none;
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
}
@media screen and (min-width: 1024px) {
  .navTreeLevel0 {
    padding: 0;
  }
  .navTreeLevel0 > li > a {
    display: block;
    padding: 22px 0;
    font-size: 18px;
    line-height: 16px;
    font-weight: 600;
  }
  .navTreeLevel0 > li:first-child > a {
    padding-top: 0;
  }
  .navTreeLevel0 > .navTreeItemInPath > a {
    color: #b83f3f;
    color: var(--color-red);
  }
  .navTreeLevel0 > .navTreeItemInPath > ul {
    position: relative;
  }
  .navTreeLevel0 > .navTreeItemInPath > ul::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0px;
    bottom: 5px;
    width: 5px;
    background-color: #b83f3f;
    background-color: var(--color-red);
  }
}
@media screen and (min-width: 1024px) {
  .navTreeLevel1 {
    padding-left: 19px;
  }
  .navTreeLevel1 > li > a {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 12px;
  }
  .navTreeLevel1 > .navTreeItemInPath {
    position: relative;
  }
  .navTreeLevel1 > .navTreeItemInPath > a {
    font-weight: 700;
    font-weight: var(--font-weight-bold);
  }
}
@media screen and (min-width: 1024px) {
  .navTreeLevel2 {
    padding-left: 12px;
    margin-bottom: 11px;
  }
  .navTreeLevel2 > li > a {
    font-size: 14px;
    font-size: var(--font-size-body);
    line-height: 24px;
  }
  .navTreeLevel2 > .navTreeItemInPath > a {
    color: #b83f3f;
    color: var(--color-red);
    font-weight: 700;
    font-weight: var(--font-weight-bold);
    padding-bottom: 9px;
    border-bottom: 1px solid #698592;
    border-bottom: 1px solid var(--color-light-petroleum);
    display: block;
  }
}
.navTreeLevel2 > .navTreeCurrentNode {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
.navTreeLevel3 {
  padding-left: 0;
  margin-bottom: 11px;
}
.navTreeLevel3 > li {
  border-bottom: 1px solid #698592;
  border-bottom: 1px solid var(--color-light-petroleum);
  padding-bottom: 9px;
  padding-top: 9px;
}
.navTreeLevel3 > li > a {
  font-size: 12px;
  font-size: var(--font-size-small);
  line-height: 18px;
}
.navTreeLevel3 > .navTreeCurrentNode > a {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
.navTreeLevel3 > .navTreeItemInPath > a {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
.navTreeLevel4 {
  padding-left: 13px;
  margin-top: 11px;
}
.navTreeLevel4 > li {
  margin-bottom: 11px;
}
.navTreeLevel4 > li > a {
  font-size: 12px;
  font-size: var(--font-size-small);
  line-height: 18px;
}
.navTreeLevel4 > .navTreeCurrentNode > a {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
.navTreeItem a:hover {
  text-decoration: underline;
  color: #b83f3f;
  color: var(--color-red);
}
.navTreeTopNode,
.navTreeDesktopHide {
  display: none;
}
body #content {
  margin-top: 42px;
  background-color: #ffffff;
  background-color: var(--color-white);
  padding: 35px 25px 0px 20px;
}
#content h1 {
  font-size: 24px;
  font-size: var(--font-size-header-large-mobile);
  line-height: var(--font-lineheight-large-medium);
  color: #243847;
  color: var(--color-header-blue);
  margin-bottom: 17px;
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
}
@media screen and (min-width: 1024px) {
  #content h1 {
    font-size: 36px;
    font-size: var(--font-size-header-large);
    line-height: 40px;
    line-height: var(--font-lineheight-large-header);
  }
}
#content h2 {
  margin-bottom: 0px;
  line-height: var(--font-lineheight-medium-header);
}
#content h3 {
  font-size: 16px;
  line-height: 26px;
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
}
#content h4 {
  font-size: 14px;
  line-height: 26px;
  line-height: var(--font-lineheight-menu);
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
}
#content .discreet {
  font-size: 12px;
  font-size: var(--font-size-small);
  line-height: 17px;
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
}
#content .documentByLine {
  color: #2e526f;
  color: var(--color-petroleum);
  margin-bottom: 17px;
}
#content .documentDescription {
  font-size: 18px;
  font-size: var(--font-size-body-large);
  line-height: 24px;
  line-height: var(--font-lineheight-body-large);
  color: #2e526f;
  color: var(--color-petroleum);
  margin-bottom: 17px;
}
#content + .last-updated {
  margin-top: 20px;
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
#content blockquote {
  font-size: 18px;
  font-size: var(--font-size-body-large);
  line-height: 28px;
  line-height: var(--font-lineheight-medium-header);
  font-style: italic;
  position: relative;
  margin: 50px 0.75em;
}
#content blockquote p {
  margin-bottom: 5px;
}
#content blockquote::before {
  content: "";
  position: absolute;
  height: 5px;
  width: 40px;
  background-color: #b83f3f;
  background-color: var(--color-red);
  top: -15px;
  left: -0.75em;
}
#content a {
  color: #b83f3f;
  color: var(--color-red);
  text-decoration: none;
}
#content a:hover {
  color: #2e526f;
  color: var(--color-petroleum);
  text-decoration: underline;
}
#content a:visited {
  color: #698592;
  color: var(--color-light-petroleum);
}
#content a.summary {
  display: block;
  margin-bottom: 15px;
  margin-top: 5px;
  font-size: var(--font-size-body-large);
  line-height: var(--font-lineheight-body);
}
#content .highlight {
  padding: 47px 18px 23px 34px;
  background-color: #4d708f;
  background-color: var(--color-bluegray);
  color: #ffffff;
  color: var(--color-white);
  font-size: 16px;
  font-size: var(--font-size-header-small);
  line-height: 20px;
  line-height: var(--font-lineheight-small-header);
  margin-bottom: 33px;
  position: relative;
}
#content .highlight::before {
  content: "";
  position: absolute;
  top: 27px;
  left: 34;
  height: 5px;
  width: 40px;
  background-color: #3e9dc1;
  background-color: var(--color-light-blue);
}
#content a.cta {
  padding: 6px 40px;
  border-radius: 40px;
  background-color: #b83f3f;
  background-color: var(--color-red);
  color: #ffffff;
  color: var(--color-white);
  text-decoration: none;
  font-size: 13px;
  line-height: 28px;
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
#content a.cta:hover {
  background-color: #952d2d;
  background-color: var(--color-dark-red);
  color: #ffffff;
  color: var(--color-white);
  text-decoration: none;
}
#content table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}
#content table tr,
#content table td,
#content table th {
  border: inherit;
}
#content table th {
  text-align: left;
}
#content table tr {
  border-bottom: 1px solid #2e526f;
  border-bottom: 1px solid var(--color-petroleum);
}
#content table td,
#content table th {
  padding: 16px 16px 16px 0;
}
#content ul {
  list-style: none;
}
#content ul ul {
  padding-left: 0;
}
#content ul li {
  position: relative;
}
#content ul li:before {
  content: "\25A0";
  color: #3e9dc1;
  color: var(--color-light-blue);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  position: absolute;
  top: -2px;
}
#portal-column-two .portlet,
#content .portlet {
  background-color: #ffffff;
  background-color: var(--color-white);
  border-radius: 6px;
  padding: 44px 20px 30px;
  margin-bottom: 20px;
}
#portal-column-two .portlet header,
#content .portlet header {
  background-color: inherit;
  color: #243847;
  color: var(--color-header-blue);
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-size: 18px;
  font-size: var(--font-size-body-large);
  line-height: 24px;
  line-height: var(--font-lineheight-body-large);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  position: relative;
  margin-bottom: 5px;
}
#portal-column-two .portlet header::before,
#content .portlet header::before {
  content: "";
  position: absolute;
  top: -14px;
  left: 0;
  background-color: #b83f3f;
  background-color: var(--color-red);
  height: 5px;
  width: 40px;
}
#portal-column-two .portlet header a,
#content .portlet header a {
  color: #243847;
  color: var(--color-header-blue);
  text-decoration: none;
}
#portal-column-two .portlet header a:hover,
#content .portlet header a:hover {
  text-decoration: underline;
}
#portal-column-two .portlet .portletContent a,
#content .portlet .portletContent a {
  color: #607885;
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
#portal-column-two .portlet .portletContent .factBox a,
#content .portlet .portletContent .factBox a {
  color: #ffffff;
  color: var(--color-white);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
#portal-column-two .portlet footer,
#content .portlet footer {
  background-color: inherit;
  color: #243847;
  color: var(--color-header-blue);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
#portal-column-two .portlet footer a,
#content .portlet footer a {
  color: #b83f3f;
  color: var(--color-red);
  text-decoration: none;
}
#portal-column-two .portlet footer a:hover,
#content .portlet footer a:hover {
  color: #243847;
  color: var(--color-header-blue);
  text-decoration: underline;
}
#portal-column-two .portlet.blue-background,
#content .portlet.blue-background {
  background-color: #2e526f;
  background-color: var(--color-petroleum);
  color: #ffffff;
  color: var(--color-white);
}
#portal-column-two .portlet.blue-background header,
#content .portlet.blue-background header {
  color: inherit;
}
#portal-column-two .portlet.blue-background header::before,
#content .portlet.blue-background header::before {
  background-color: #3e9dc1;
  background-color: var(--color-light-blue);
}
#portal-column-two .portlet.blue-background header a,
#content .portlet.blue-background header a {
  color: inherit;
}
#portal-column-two .portlet.blue-background footer,
#content .portlet.blue-background footer {
  color: inherit;
}
#portal-column-two .portlet.blue-background footer a,
#content .portlet.blue-background footer a {
  color: inherit;
}
#portal-column-two .portlet.darkgray-background,
#content .portlet.darkgray-background {
  background-color: #698592;
  background-color: var(--color-light-petroleum);
  color: #ffffff;
  color: var(--color-white);
}
#portal-column-two .portlet.darkgray-background header,
#content .portlet.darkgray-background header {
  color: inherit;
}
#portal-column-two .portlet.darkgray-background header::before,
#content .portlet.darkgray-background header::before {
  background-color: #334f59;
  background-color: var(--color-dark-blue);
}
#portal-column-two .portlet.darkgray-background header a,
#content .portlet.darkgray-background header a {
  color: inherit;
}
#portal-column-two .portlet.darkgray-background footer,
#content .portlet.darkgray-background footer {
  color: inherit;
}
#portal-column-two .portlet.darkgray-background footer a,
#content .portlet.darkgray-background footer a {
  color: inherit;
}
#portal-column-two .portlet.turquise-background,
#content .portlet.turquise-background {
  background-color: #4d708f;
  background-color: var(--color-bluegray);
  color: #ffffff;
  color: var(--color-white);
}
#portal-column-two .portlet.turquise-background header,
#content .portlet.turquise-background header {
  color: inherit;
}
#portal-column-two .portlet.turquise-background header::before,
#content .portlet.turquise-background header::before {
  background-color: #3e9dc1;
  background-color: var(--color-light-blue);
}
#portal-column-two .portlet.turquise-background header a,
#content .portlet.turquise-background header a {
  color: inherit;
}
#portal-column-two .portlet.turquise-background footer,
#content .portlet.turquise-background footer {
  color: inherit;
}
#portal-column-two .portlet.turquise-background footer a,
#content .portlet.turquise-background footer a {
  color: inherit;
}
#portal-column-two .portlet.border-background,
#content .portlet.border-background {
  background-color: #f1f3f5;
  background-color: var(--color-broken-white);
  border: 2px solid #ffffff;
  border: 2px solid var(--color-white);
}
#portal-column-two .portlet.no-background,
#content .portlet.no-background {
  background-color: #f1f3f5;
  background-color: var(--color-broken-white);
}
#content .portletWrapper {
  margin-bottom: 20px;
}
#results-bar {
  font-size: 18px;
  font-size: var(--font-size-body-large);
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  line-height: var(--font-limeheight-body-large);
  color: #2e526f;
  color: var(--color-petroleum);
  padding: 20px 0;
  margin: 10px 0 50px;
}
#advanced-results {
  background-color: #f1f3f5;
  background-color: var(--color-light-gray);
  margin-left: -20px;
  margin-right: -25px;
  padding: 5px 0 0 0;
  margin-top: -40px;
}
.publicationSearchBox {
  color: #ffffff;
  color: var(--color-white);
  background-color: #4d708f;
  background-color: var(--color-bluegray);
  margin-top: 20px;
  margin-left: -20px;
  margin-right: -25px;
  padding: 46px 20px 66px 25px;
}
.searchRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 10px;
  margin-left: 0;
}
.searchRow span {
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  line-height: 40px;
}
.searchRow .searchInput {
  border-radius: 10px 10px 10px 10px;
  width: 75%;
  height: 35px;
  border: none;
}
@media screen and (min-width: 1024px) {
  .searchRow .searchInput {
    width: 80%;
  }
}
.searchRow #searchable {
  padding: 8px;
}
.searchRow select {
  font-family: "Lato", serif, sans-serif;
  font-family: var(--font-lato);
  font-size: 14px;
  font-size: var(--font-size-body);
  color: #2e526f;
  color: var(--color-petroleum);
  padding: 8px;
}
.searchButton {
  background-color: #b83f3f;
  background-color: var(--color-red);
  color: #ffffff;
  color: var(--color-white);
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  font-size: 14px;
  font-size: var(--font-size-body);
  border-radius: 40px 40px 40px 40px;
  margin: 10px 10px 0 0;
  padding: 6px 25px;
  border: none;
  float: right;
}
/* LIST */
.publicationItem {
  background-color: #ffffff;
  background-color: var(--color-white);
  margin: 15px 0 10px 0;
  padding: 5px 20px 5px;
}
#content .year {
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
#content .subject {
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-style: italic;
  font-style: var(--font-style-italic);
}
#content .documentByLine {
  margin-top: 30px;
  margin-bottom: 0;
}
#content a {
  color: #334f59;
  color: var(--color-red);
  text-decoration: underline;
}
/*PAGING*/
.listingAmount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 40px;
}
.listingBar {
  padding-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 30px 0px;
}
#content a.nextBatch,
#content a.previousBatch {
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  background-color: #b83f3f;
  background-color: var(--color-red);
  color: #ffffff;
  color: var(--color-white);
  font-size: 14px;
  font-size: var(--font-size-body);
  border-radius: 40px 40px 40px 40px;
  padding: 4px 26px;
  height: 30px;
  margin-top: 4px;
}
#content a.nextBatch.nextBatch,
#content a.previousBatch.nextBatch {
  float: right;
}
#content a.nextBatch.previousBatch,
#content a.previousBatch.previousBatch {
  float: left;
}
#content .batchNumbers a {
  padding: 4px;
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-size: 14px;
  font-size: var(--font-size-body);
}
#content a.itemBatch {
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-size: 14px;
  font-size: var(--font-size-body);
  padding: 0;
}
@media screen and (min-width: 1024px) {
  #content a.itemBatch {
    padding: 8px;
  }
}
#content .linkButton a {
  background-color: #b83f3f;
  background-color: var(--color-red);
  color: #ffffff;
  color: var(--color-white);
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  padding: 10px 53px 10px 55px;
  border-radius: 40px;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
}
#content .linkButton a:hover {
  background-color: #952d2d;
  background-color: var(--color-dark-red);
}
.portletPublication {
  background-color: #2e526f;
  background-color: var(--color-petroleum);
  color: #ffffff;
  color: var(--color-white);
  border-radius: 6px;
  padding: 44px 20px 30px;
  margin-bottom: 20px;
}
.portletPublication .portletHeader {
  background-color: inherit;
  color: #ffffff;
  color: var(--color-white);
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-size: 18px;
  font-size: var(--font-size-body-large);
  line-height: 24px;
  line-height: var(--font-lineheight-body-large);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  position: relative;
  margin-bottom: 5px;
}
.portletPublication .portletHeader::before {
  content: "";
  position: absolute;
  top: -14px;
  left: 0;
  background-color: #3e9dc1;
  background-color: var(--color-light-blue);
  height: 5px;
  width: 40px;
}
.portletPublication .portletHeader a {
  color: #ffffff;
  color: var(--color-white);
  text-decoration: none;
}
.portletPublication dd {
  margin: 0 0 20px;
}
.portletPublication dd ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.portletPublication dd ul li:before {
  content: "-";
  margin-right: 10px;
}
.documentFirstHeading {
  padding: 0;
}
#content-core .discreet {
  font-size: 14px;
  font-size: var(--font-size-body);
}
.form {
  padding: 0;
}
.form label {
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-size: 16px;
  font-size: var(--font-size-header-small);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  color: #243847;
  color: var(--color-header-blue);
  margin-top: 10px;
  padding-top: 5px;
}
.form label .formHelp {
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-weight: 300;
  font-weight: var(--font-weight-light);
  font-size: 14px;
  font-size: var(--font-size-body);
  font-style: italic;
  font-style: var(--font-style-italic);
  color: #698592;
  color: var(--color-light-petroleum);
  display: block;
}
.form .text-widget {
  background-color: #f1f3f5;
  background-color: var(--color-broken-white);
  border: 1px solid #698592;
  border: 1px solid var(--color-light-petroleum);
  border-radius: 10px;
  width: 100%;
  height: 50px;
  padding-left: 8px;
}
.form .text-field {
  background-color: #f1f3f5;
  background-color: var(--color-broken-white);
  border: 1px solid #698592;
  border: 1px solid var(--color-light-petroleum);
  width: 100%;
  height: 150px;
  border-radius: 10px;
  resize: none;
}
.form .formControls {
  padding-bottom: 50px;
}
.form input[type='radio'] {
  position: relative;
}
.form input[type='radio']:after {
  border: 2px solid #698592;
  border: 2px solid var(--color-light-petroleum);
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: inline-block;
  background-color: white;
  position: absolute;
  top: -3px;
  left: 3px;
  content: '';
}
.form input[type='radio']:checked:after {
  background-color: #698592;
  background-color: var(--color-light-petroleum);
  content: '';
  border: none;
}
.form #form-buttons-submit {
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-size: 14px;
  font-size: var(--font-size-body);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  background-color: #b83f3f;
  background-color: var(--color-red);
  color: #ffffff;
  color: var(--color-white);
  margin-top: 30px;
  padding: 6px 36px;
  border-radius: 40px;
  border: none;
  float: right;
  margin-bottom: 20px;
  cursor: pointer;
}
.form .label {
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
  font-size: 14px;
  font-size: var(--font-size-body);
  color: #243847;
  color: var(--color-header-blue);
  margin-left: 8px;
}
.form .picker__weekday,
.form .picker__day,
.form .picker__list-item {
  font-family: "Open Sans", serif, sans-serif;
  font-family: var(--font-open);
}
.form .pattern-pickadate-time {
  margin-top: 20px;
}
.form ul {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 4;
  grid-column-gap: 20px;
  column-gap: 20px;
}
.form #formPrologue ul {
  column-count: 1;
}
#SubscriberForm .mc-field-group ul li label {
  display: inline;
  padding-left: 8px;
}
#SubscriberForm .mc-field-group ul li:before {
  content: none;
}
#SubscriberForm table tbody tr {
  border: none;
}
.turquoiseBackground {
  background-color: #67a9b4;
}
#dk-map-black {
  background: linear-gradient(to right, grey, black, grey) no-repeat center;
  height: 495px;
  width: 411px;
  background-size: 98% 99%;
}
body.section-front-page #visual-portal-wrapper {
  margin-bottom: 0px;
}
body.section-front-page .breadcrumb-container {
  display: none;
}
body.section-front-page #top-image-container {
  display: none;
}
.footer-wrapper .portlet {
  border: none;
}
.footer-wrapper .portlet .portletHeader {
  display: none;
}
.footer-wrapper .portlet .portletContent {
  background: none;
  border: none;
}
.footer-wrapper .portlet .portletContent ul li a {
  color: #ffffff;
  margin: 0px 60px 0px 0px;
  border-top: none;
  padding: 0px;
}
/*body.site-site*/
body.section-front-page #content {
  font-family: "Open Sans", serif, sans-serif;
  padding: 0px 25px 35px 25px;
}
body.section-front-page #content .documentFirstHeading,
body.section-front-page #content .documentActions {
  display: none;
}
body.section-front-page #content .documentDescription {
  font-size: 16px;
}
body.section-front-page #content #content-core {
  font-size: 14px;
}
body.section-front-page #content > header {
  display: none;
}
body.section-front-page #content .portletWrrapper {
  margin-bottom: 0px;
}
body.section-front-page #content h1 {
  font-family: "Open Sans", serif, sans-serif;
  font-weight: bold;
  font-size: 42px;
  color: #ffffff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  body.section-front-page #content h1 {
    font-size: 34px;
  }
}
body.section-front-page #content h2 {
  font-family: "Open Sans", serif, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  color: #334f59;
}
body.section-front-page #content div.button {
  border: none;
  height: 50px;
  display: block;
  width: 150px;
  padding: 0px;
  background-color: transparent;
}
body.section-front-page #content a.button {
  font-family: "Open Sans", serif, sans-serif;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 14px;
  background-color: #b83f3f;
  border: none;
}
body.section-front-page #content .tb-wrapper {
  float: left;
  width: 50%;
  position: relative;
  padding-right: 2px;
}
@media only screen and (max-width: 768px) {
  body.section-front-page #content .tb-wrapper {
    width: 100%;
    margin-top: -3px;
    max-width: 450px;
  }
}
body.section-front-page #content .tb-text {
  top: 20%;
  margin: 30px;
  color: #ffffff;
  font-size: 16px;
  position: initial;
  margin-left: 0;
}
@media only screen and (max-width: 768px) {
  body.section-front-page #content .tb-text {
    float: none;
    width: 92%;
    margin: 0px 10px;
    top: 5%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  body.section-front-page #content .tb-text {
    top: 0px;
  }
}
body.section-front-page #content .tb-text a {
  text-decoration: none;
  color: #ffffff;
  width: 200px;
  height: 40px;
  line-height: 1;
}
body.section-front-page #content .tb-text a h1 {
  color: initial;
}
body.section-front-page #content .tb-text .tb-description {
  font-family: "Open Sans", serif, sans-serif;
  font-weight: 300;
  width: 98%;
  margin-bottom: 40px;
  color: initial;
}
body.section-front-page #content .tb-text a.button:hover {
  color: #ffffff;
  background-color: #923232;
}
body.section-front-page #content .about-ast-wrapper {
  margin-top: 45px;
}
body.section-front-page #content .about-ast-wrapper .about-ast-image {
  width: 51%;
  float: left;
}
@media only screen and (max-width: 768px) {
  body.section-front-page #content .about-ast-wrapper .about-ast-image {
    float: none;
    width: 100%;
  }
}
body.section-front-page #content .about-ast-wrapper .about-ast-text {
  padding-top: 10px;
}
@media only screen and (max-width: 768px) {
  body.section-front-page #content .about-ast-wrapper .about-ast-text {
    padding-top: 0px;
  }
}
body.section-front-page #content .about-ast-wrapper .about-ast-description {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #2e526f;
  margin-bottom: 40px;
}
body.section-front-page #content .about-ast-wrapper .about-ast-button {
  background-color: #327E9A;
  color: #ffffff;
  text-decoration: none;
}
body.section-front-page #content .about-ast-wrapper .about-ast-button:hover {
  background-color: #255e73;
}
body.section-front-page #content .kj-wrapper {
  margin-top: 20px;
}
body.section-front-page #content .kj-wrapper hr {
  margin: 55px 0px;
  border-bottom: 1px solid #d7dbdf;
  height: auto;
}
body.section-front-page #content .kj-wrapper .kj-block-left {
  margin-right: 26px;
}
body.section-front-page #content .kj-wrapper .kj-block {
  padding-bottom: 20px;
  float: left;
  width: 48.3%;
}
@media only screen and (max-width: 768px) {
  body.section-front-page #content .kj-wrapper .kj-block {
    width: 100%;
    padding-bottom: 50px;
  }
}
body.section-front-page #content .kj-wrapper .kj-block .kj-text {
  margin-top: 30px;
}
body.section-front-page #content .kj-wrapper .kj-block .kj-text h2 {
  margin-bottom: 10px;
}
body.section-front-page #content .kj-wrapper .kj-block .kj-description {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #2e526f;
  margin-bottom: 40px;
}
body.section-front-page #content .kj-wrapper .kj-block .kj-button {
  background-color: #327E9A;
  color: #ffffff;
  text-decoration: none;
  display: block;
  width: 110px;
  padding-bottom: 30px;
  padding-top: 15px;
}
body.section-front-page #content .kj-wrapper .kj-block .kj-button:hover {
  background-color: #255e73;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader {
  background-color: #f1f3f5;
  padding: 60px 80px;
  margin-top: 30px;
  margin-bottom: 0px;
}
@media only screen and (max-width: 768px) {
  body.section-front-page #content #viewlet-below-content-body .largeHeader {
    left: -5%;
    padding: 60px 10% 30px 10%;
  }
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .portletHeader {
  display: none;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0px;
  display: table;
  width: 100%;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .portletItemByline {
  text-transform: none;
  margin-bottom: 14px;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .portletItemTitle {
  border-bottom: none;
  text-transform: none;
  text-decoration: none;
  margin-bottom: 0px;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .firstItem {
  float: left;
  width: 50%;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .firstItem .item-button {
  color: #ffffff;
  background-color: #327E9A;
  height: 45px;
  text-decoration: none;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .firstItem .item-button:hover {
  background-color: #255e73;
}
@media only screen and (max-width: 768px) {
  body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .firstItem {
    width: 100%;
    float: none;
    height: auto;
    margin-bottom: 60px;
  }
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .otherItem {
  border-bottom: 1px solid #d7dbdf;
  margin-left: 60%;
  margin-bottom: 15px;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .otherItem h2 {
  font-size: 16px;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .otherItem .portletItemDescription {
  display: none;
}
@media only screen and (max-width: 768px) {
  body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .otherItem {
    margin-left: 0px;
  }
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .portletItem h2 {
  margin-top: 0px;
  margin-bottom: 20px;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .portletItem a:focus {
  outline: none;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .portletItem a:hover {
  text-decoration: none;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .portletItem a:hover h2 {
  color: #b83f3f;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .portletItem .portletItemByline {
  font-family: "Open Sans", serif, sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #5F7181;
  margin-bottom: 10px;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .portletItem .portletItemDescription {
  font-family: "Open Sans", serif, sans-serif;
  font-size: 16px;
  padding-bottom: 40px;
  color: #2e526f;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .portletFooter {
  margin-left: 60%;
  border: none;
  background-color: transparent;
  box-shadow: none;
  margin-top: 20px;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .portletFooter a {
  font-family: "Open Sans", serif, sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  color: #334f59;
  text-decoration: none;
}
body.section-front-page #content #viewlet-below-content-body .largeHeader .NGFrontpage .portletFooter a:hover {
  color: #b83f3f;
}
body.section-front-page #content .frontpage-astbox .wrapper {
  width: 100%;
  border: 1px solid #eaeaea;
  color: black;
  margin-top: 50px;
  padding: 50px;
}
body.section-front-page #content .frontpage-astbox .wrapper h2 {
  margin-top: 0px;
  margin-bottom: 20px;
}
body.section-front-page #content .frontpage-astbox .wrapper .text-body {
  font-family: Lato, sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #2e526f;
}
body.section-front-page #content .frontpage-astbox .wrapper .button-wrapper {
  margin-top: 40px;
}
body.section-front-page #content .frontpage-astbox .wrapper .button-wrapper a {
  color: #ffffff;
  background-color: #327E9A;
  text-decoration: none;
  display: inline-block;
  font-family: 'Open Sans', serif, sans-serif;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 14px;
  border: none;
}
/* Accordion */
#accordion {
  margin-bottom: 18px;
}
.accordionReadAll {
  clear: both;
  display: block;
}
.accordionReadAll h3 {
  display: inline;
  font-family: 'Swift W01 Light', serif;
}
.accordionReadAll h3:hover {
  text-decoration: underline;
}
.accordionReadAll,
#accordion > h3 {
  border-bottom: 1px solid #a7b0b5;
  color: #008858;
  cursor: pointer;
  padding: 12px 32px 12px 14px;
  position: relative;
}
.accordionReadAll h3:after {
  background: url(data:image/gif;base64,R0lGODlhCAAIAIABAACIWP///yH5BAEAAAEALAAAAAAIAAgAAAINjAGmgXv5kJxUNXdXLgA7) no-repeat 0 0;
  content: "";
  display: inline-block;
  height: 8px;
  margin: 0 0 3px 9px;
  vertical-align: middle;
  width: 8px;
}
#accordion > h3:after {
  background: url(data:image/gif;base64,R0lGODlhCAAIAIABAACIWP///yH5BAEAAAEALAAAAAAIAAgAAAINjAGmgXv5kJxUNXdXLgA7) no-repeat 0 50%;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: 15px;
  top: 17px;
  width: 8px;
}
#accordion > h3 {
  font-family: 'Open Sans', sans-serif;
}
#accordion > h3:hover {
  background-color: #e9eff3;
}
#accordion > h3.current {
  background-color: #e9eff3;
  border: 0;
}
#accordion > h3.current:after {
  background-image: url(data:image/gif;base64,R0lGODlhCAACAIAAAAAaSwAAACH5BAAAAAAALAAAAAAIAAIAAAIEhI8JBQA7);
}
#accordion > h3 a:hover {
  text-decoration: none;
}
#accordion > h3.current a {
  color: #001a4b;
}
#accordion > div {
  background-color: #fff;
  border-bottom: 1px solid #a7b0b5;
  margin-top: -1px;
  padding: 6px 14px;
  position: relative;
}
#accordion .current + div {
  background-color: #e9eff3;
}
.accordionReadAll.openedAll h3:after {
  background-image: url(data:image/gif;base64,R0lGODlhCAACAIAAAAAaSwAAACH5BAAAAAAALAAAAAAIAAIAAAIEhI8JBQA7);
  margin-top: 8px;
}
body.section-front-page .site-wrapper #content {
  margin-top: 0px;
}
body.section-front-page .plone-modal .plone-modal-content {
  background-color: #fff;
}
.nav-wrapper #adgang-for-alle {
  position: absolute;
  top: -64px;
  right: 142px;
  width: 26px;
}
.nav-wrapper #adgang-for-alle a img:hover {
  transform: scale(1.1);
}
.nav-wrapper #emneoversigt-menu a {
  color: #2e526f;
  font-weight: 700;
  position: absolute;
  top: -62px;
  right: 35px;
  font-family: "Open Sans", serif, sans-serif;
}
.nav-wrapper #emneoversigt-menu a:hover {
  color: #b83f3f;
}
.nav-wrapper nav {
  width: 100%;
  text-align: center;
  border: 1px solid #eaeaea;
  color: black;
  margin-left: 25px;
  margin-right: 25px;
  padding: 30px;
}
.nav-wrapper .top-menu-box .heading {
  display: inline;
  color: #334f59;
  font-family: "Open Sans", serif, sans-serif;
  font-size: var(--font-size-header-small);
  margin: 0 0 0.5em 0;
  font-weight: 700;
}
.nav-wrapper .top-menu-box a {
  margin-left: 10px;
  color: #ffffff;
  background-color: #327E9A;
  height: 40px;
  width: 200px;
  text-decoration: none;
  display: inline-block;
  font-family: "Open Sans", serif, sans-serif;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 14px;
  border: none;
}
.nav-wrapper .top-menu-box a:hover {
  background-color: #327e9a;
}
.nav-wrapper .search-container {
  top: -66px;
  position: absolute;
  right: 0px;
}
@media screen and (max-width: 550px) {
  .nav-wrapper #adgang-for-alle {
    top: -2px;
    right: 168px;
  }
  .nav-wrapper #emneoversigt-menu a {
    top: 0;
    right: 60px;
  }
  .nav-wrapper nav {
    margin-top: 50px;
  }
  .nav-wrapper .search-container {
    top: -6px;
    right: 24px;
  }
}
#emneoversigt-overlay {
  font-family: "Open Sans", serif, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  transform-origin: 0% 0%;
  transform: translate(0, -100%);
  overflow: scroll;
}
#emneoversigt-overlay .oversigt-content {
  max-width: 920px;
  height: 100vh;
  width: 100%;
  display: block;
  margin: 0px auto;
  background-color: #fff;
  color: #2e526f;
}
#emneoversigt-overlay .oversigt-content .oversigt-body {
  padding-top: 40px;
}
#emneoversigt-overlay .close-emneoversigt-button {
  float: right;
  font-size: 30px;
  font-weight: 700;
  margin: 15px;
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  box-shadow: none;
  margin: 0;
}
#emneoversigt-overlay .close-emneoversigt-button:hover {
  color: #b83f3f;
}
#emneoversigt-overlay.show-oversigt-overlay {
  transform: none;
}
body.template-tags-list-view #top-image-container,
body.template-term-view #top-image-container {
  display: none;
}
#content-over,
#content {
  margin-top: 0px;
  font-family: "Open Sans", serif, sans-serif;
  padding: 0px 25px 35px 25px;
  background-color: var(--color-white);
}
#content-over .tag-list,
#content .tag-list {
  font-family: "Open Sans", serif, sans-serif;
}
#content-over .tag-list .heading,
#content .tag-list .heading {
  text-align: center;
}
#content-over .tag-list .heading div,
#content .tag-list .heading div {
  border: 1px solid;
  max-width: 350px;
  margin: 10px auto;
  padding: 14px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 30px;
}
#content-over .tag-list .letters,
#content .tag-list .letters {
  text-align: center;
  margin-bottom: 40px;
}
#content-over .tag-list .letters a.active,
#content .tag-list .letters a.active {
  color: inherit;
}
#content-over .tag-list .letters a.active:hover,
#content .tag-list .letters a.active:hover {
  color: #b83f3f;
}
#content-over .tag-list .letters span,
#content .tag-list .letters span {
  color: #757575;
}
#content-over .tag-list .row .col-sm-4,
#content .tag-list .row .col-sm-4 {
  margin-bottom: 15px;
}
#content-over .tag-list .row .col-sm-4 .letter,
#content .tag-list .row .col-sm-4 .letter {
  font-size: 16px;
  font-weight: 700;
}
#content-over .tag-list .tag-term,
#content .tag-list .tag-term {
  background-color: #e2e2e2;
  border-radius: 10px;
  padding: 5px;
  margin: 2px;
  float: left;
  clear: left;
  display: block;
  color: #646464;
  text-decoration: none;
}
#content-over .tag-list .tag-term:hover,
#content .tag-list .tag-term:hover {
  text-decoration: underline;
}
#content-over .tag-list .toogle-other-terms-button,
#content .tag-list .toogle-other-terms-button {
  cursor: pointer;
  font-weight: 700;
  padding: 6px;
  border: none;
  box-shadow: none;
  background: none;
}
#content-over .tag-list .toogle-other-terms-button:hover,
#content .tag-list .toogle-other-terms-button:hover {
  color: #b83f3f;
}
#content-over .tag-list div[class^="other-terms-"],
#content .tag-list div[class^="other-terms-"] {
  display: block;
  clear: both;
}
#content-over .tag-list .slider-other-terms,
#content .tag-list .slider-other-terms {
  max-height: 0;
  transition: max-height 0.15s cubic-bezier(0.42, 0.15, 0.22, 1.015);
  overflow: hidden;
  clear: both;
}
#content-over .tag-list .slider-other-terms.show-others,
#content .tag-list .slider-other-terms.show-others {
  max-height: 5000px;
  transition: max-height 0.5s cubic-bezier(0.42, 0.15, 0.22, 1.015);
}
#content-over .tag-view,
#content .tag-view {
  font-family: "Open Sans", serif, sans-serif;
  padding-bottom: 30px;
}
#content-over .tag-view .leads,
#content .tag-view .leads {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 0px 0px 30px 0px;
}
#content-over .tag-view .leads .lead-item,
#content .tag-view .leads .lead-item {
  font-size: 24px;
}
#content-over .tag-view .leads .lead-item .item-title,
#content .tag-view .leads .lead-item .item-title {
  font-weight: 700;
  margin-bottom: 5px;
  display: block;
  margin-top: 30px;
  text-decoration: none;
}
#content-over .tag-view .leads .lead-item .item-title:hover,
#content .tag-view .leads .lead-item .item-title:hover {
  text-decoration: underline;
}
#content-over .tag-view .leads .lead-item .item-tag,
#content .tag-view .leads .lead-item .item-tag {
  background-color: #e2e2e2;
  border-radius: 10px;
  padding: 0px 4px;
  margin: 2px;
  display: inline-block;
  font-size: 12px;
  color: #646464;
  text-decoration: none;
}
#content-over .tag-view .leads .lead-item .item-tag:hover,
#content .tag-view .leads .lead-item .item-tag:hover {
  text-decoration: underline;
}
#content-over .tag-view .leads .lead-item .item-description,
#content .tag-view .leads .lead-item .item-description {
  margin-top: 10px;
  margin-bottom: 18px;
  display: block;
  font-size: 16px;
}
#content-over .tag-view .leads .lead-item .item-title-button,
#content .tag-view .leads .lead-item .item-title-button {
  color: #ffffff;
  background-color: #3e9dc1;
  height: 45px;
  font-family: "Open Sans", serif, sans-serif;
  border-radius: 25px;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 12px;
  border: none;
  text-decoration: none;
}
#content-over .tag-view .leads .lead-item .item-title-button:hover,
#content .tag-view .leads .lead-item .item-title-button:hover {
  background-color: #327e9a;
}
#content-over .tag-view .tag-tool,
#content .tag-view .tag-tool {
  margin: 40px 0px;
}
#content-over .tag-view .tag-tool .result-count,
#content .tag-view .tag-tool .result-count {
  font-size: 24px;
}
#content-over .tag-view .tag-tool .tag-sorting,
#content .tag-view .tag-tool .tag-sorting {
  float: right;
}
#content-over .tag-view .tag-item,
#content .tag-view .tag-item {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
}
#content-over .tag-view .tag-item .item-title,
#content .tag-view .tag-item .item-title {
  font-weight: 700;
  text-decoration: none;
  font-size: 18px;
}
#content-over .tag-view .tag-item .item-title:hover,
#content .tag-view .tag-item .item-title:hover {
  text-decoration: underline;
}
#content-over .tag-view .tag-item .item-tag,
#content .tag-view .tag-item .item-tag {
  background-color: #e2e2e2;
  border-radius: 10px;
  padding: 0px 4px;
  margin: 2px;
  display: inline-block;
  font-size: 12px;
  color: #646464;
  text-decoration: none;
}
#content-over .tag-view .tag-item .item-tag:hover,
#content .tag-view .tag-item .item-tag:hover {
  text-decoration: underline;
}
#portal-column-two .portlet,
#content .portletEmneordTag {
  padding: 30px 20px 30px;
}
#portal-column-two .portlet .item-tag,
#content .portletEmneordTag .item-tag {
  background-color: #e2e2e2;
  border-radius: 10px;
  padding: 5px;
  white-space: nowrap;
  line-height: 30px;
  color: #646464;
}
/*# sourceMappingURL=++theme++ast/less/theme-compiled.less.map */